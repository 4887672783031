import React from 'react';
import { HeaderMenu } from './components/HeaderMenu';
import { Home } from './screens/Home';
import { Courses } from './screens/Courses';
import { About } from './screens/About';
import { Contact } from './screens/Contact';

import { Routes, Route} from "react-router-dom";


function App() {
  return (
    <Routes >
      <Route path="/" element={<HeaderMenu />}>
        <Route index element={<Home />} />
        <Route path='cursos' element={<Courses />} />
        <Route path='sobre-mi' element={<About />} />
        <Route path='contacto' element={<Contact />} />
    </Route>
  </Routes>
  );
}

export default App;