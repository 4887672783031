import React from "react";
import styled from "@emotion/styled";

export function Contact() {
  return (
    <RootWrapperContacto>
      <MaskGroup>
        <MaskGroup_0001>
          <Bg>
            <JesusChristGivesAHelpingHandToTheFaithful20210826162625Utc1 xmlns="http://www.w3.org/2000/svg">
              <path fill="transparent" d="M0 0L3153.32 0L3153.32 1637.18L0 1637.18L0 0Z"/>
            </JesusChristGivesAHelpingHandToTheFaithful20210826162625Utc1>
            <_06Background1 xmlns="http://www.w3.org/2000/svg">
              <path fill="transparent" d="M0 0L1370.63 0L1370.63 3142.97L0 3142.97L0 0Z"/>
            </_06Background1>
          </Bg>
        </MaskGroup_0001>
      </MaskGroup>
      <Group14>
        <Rectangle6/>
        <Group13>
          <Group11>
            <Group12>
              <Rectangle2/>
            </Group12>
            <Rectangle2_0001/>
            <CorreoElectrónico>
              Correo electrónico
            </CorreoElectrónico>
          </Group11>
          <IconsaxLinearSend>
            <Vector src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/9d6e1f02-c57f-42db-b5de-5ea28d1af419" alt="image of Vector"/>
          </IconsaxLinearSend>
        </Group13>
        <Line1/>
        <NamasteBlanco1 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/678bd8ad-faad-4341-9ecd-9e272a18728e" alt="image of NamasteBlanco1"/>
        <AcademíaDescúbrete>
          academía descúbrete
        </AcademíaDescúbrete>
        <Contacto_0001>
          contacto
        </Contacto_0001>
        <NochesDeNamasteMeditaton>
          Noches de namaste<br/>
Meditaton
        </NochesDeNamasteMeditaton>
        <RecibeNuestrasPromocionesONoticiasAntesQueNadie>
          Recibe nuestras promociones<br/>
o noticias antes que nadie
        </RecibeNuestrasPromocionesONoticiasAntesQueNadie>
        <ÚneteAlTeam>
          Únete al team
        </ÚneteAlTeam>
        <HolaYessnamasteCom>
          hola@yessnamaste.com
        </HolaYessnamasteCom>
        <CentroDeAyudaPreguntasFrecuentesPoliticasDePrivacidadTerminosYCondiciones>
          Centro de ayuda
<br/>
Preguntas frecuentes
<br/>
Politicas de privacidad
<br/>
Terminos y condiciones
        </CentroDeAyudaPreguntasFrecuentesPoliticasDePrivacidadTerminosYCondiciones>
        <SíguemeEnMisRedes>
          Sígueme en mis redes
        </SíguemeEnMisRedes>
        <YessNamaste2023>
          © Yess Namaste 2023 
        </YessNamaste2023>
        <IconsaxLinearInstagram src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/4187a4ad-9fb0-4c3e-9637-d3c98cba78a5" alt="icon"/>
        <IconsaxLinearFacebook src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/d6ac1a13-e16b-4578-b72b-86d20d45d68e" alt="icon"/>
      </Group14>
      <HolaCorazoncitoNamaste>
        Hola, <br/>
corazoncito<br/>
namaste
      </HolaCorazoncitoNamaste>
      <QueremosEscucharteCuentanosSobreTíYLoQueAcontece>
        Queremos escucharte, cuentanos sobre tí y lo que acontece...
      </QueremosEscucharteCuentanosSobreTíYLoQueAcontece>
      <Group18>
        <Rectangle7/>
        <Rectangle8/>
        <Nombre>
          Nombre*
        </Nombre>
        <CorreoElectrónico_0001>
          Correo electrónico *
        </CorreoElectrónico_0001>
        <Teléfono>
          Teléfono
        </Teléfono>
        <CuentanosloTodo>
          Cuentanoslo todo...
        </CuentanosloTodo>
        <Line2 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/02507a0b-7dfd-419c-be4a-ce7ace86fa82" alt="image of Line2"/>
        <Line3 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/40f6af96-4fc3-43c5-b607-fb7493797bc0" alt="image of Line3"/>
        <Line4 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/ecea1f18-b5ec-456d-8fc3-67eb2dbc9aa6" alt="image of Line4"/>
        <Group16>
          <Rectangle2_0002/>
          <Enviar>
            enviar
          </Enviar>
          <QueremosEscucharte>
            Queremos escucharte
          </QueremosEscucharte>
        </Group16>
        <Vector_0001 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/a1db0091-ad2f-464e-a1af-330ff1095624" alt="image of Vector"/>
      </Group18>
      <IconsaxLinearCallreceived src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/dcce4751-8378-473e-a0c5-f01b2ff2726d" alt="icon"/>
      <IconsaxLinearSms src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/58822331-25f7-4fec-8b70-6e4011ee410d" alt="icon"/>
    </RootWrapperContacto>
  )
}

const RootWrapperContacto = styled.div`
	min-height: 100vh;
	background-color: white;
	position: relative;
`;

const MaskGroup = styled.div`
	width: 1929px;
	height: 976px;
	position: absolute;
	left: -13px;
	top: 104px;
`;

const MaskGroup_0001 = styled.div`
	width: 1929px;
	height: 976px;
	position: absolute;
	clip-path: path("M0 0L1928.96 0L1928.96 976.471L0 976.471L0 0Z");
`;

const Bg = styled.div`
	width: 3177px;
	height: 1715px;
	position: absolute;
	left: -1221px;
	top: -622px;
`;

const JesusChristGivesAHelpingHandToTheFaithful20210826162625Utc1 = styled.svg`
	width: 3153px;
	height: 1637px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const _06Background1 = styled.svg`
	width: 1371px;
	height: 3143px;
	position: absolute;
	left: 34px;
	top: 1715px;
	transform: rotate(-90deg);
	transform-origin: top left;
`;

const Group14 = styled.div`
	width: 1920px;
	height: 466px;
	position: absolute;
	left: 0px;
	top: 1080px;
`;

const Rectangle6 = styled.div`
	width: 1920px;
	height: 466px;
	background-color: rgb(0, 140, 154);
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Group13 = styled.div`
	width: 344px;
	height: 48px;
	position: absolute;
	left: 511px;
	top: 222px;
`;

const Group11 = styled.div`
	width: 344px;
	height: 48px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Group12 = styled.div`
	width: 60px;
	height: 48px;
	position: absolute;
	left: 284px;
	top: -0px;
`;

const Rectangle2 = styled.div`
	width: 60px;
	height: 48px;
	background-color: white;
	border-radius: 6px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Rectangle2_0001 = styled.div`
	width: 344px;
	height: 48px;
	border: solid 2px white;
	border-radius: 6px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const CorreoElectrónico = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: left;
	width: 225px;
	min-height: 20px;
	position: absolute;
	left: 31px;
	top: 10px;
	height: 20px;
`;

const IconsaxLinearSend = styled.div`
	width: 32px;
	height: 31px;
	overflow: hidden;
	position: absolute;
	left: 299px;
	top: 7px;
`;

const Vector = styled.img`
	object-fit: cover;
	position: absolute;
	left: 4px;
	top: 4px;
	right: 4px;
	bottom: 4px;
`;

const Line1 = styled.div`
	width: 1920px;
	height: 0px;
	border-top: solid 1px white;
	position: absolute;
	left: 0px;
	top: 375px;
`;

const NamasteBlanco1 = styled.img`
	width: 232px;
	height: 174px;
	object-fit: cover;
	position: absolute;
	left: 163px;
	top: 54px;
`;

const AcademíaDescúbrete = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 24px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: justify;
	text-transform: uppercase;
	position: absolute;
	left: 919px;
	top: 79px;
`;

const Contacto_0001 = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 24px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: justify;
	text-transform: uppercase;
	position: absolute;
	left: 1370px;
	top: 79px;
`;

const NochesDeNamasteMeditaton = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	position: absolute;
	left: 919px;
	top: 137px;
`;

const RecibeNuestrasPromocionesONoticiasAntesQueNadie = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	position: absolute;
	left: 511px;
	top: 137px;
`;

const ÚneteAlTeam = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 24px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: justify;
	text-transform: uppercase;
	position: absolute;
	left: 511px;
	top: 88px;
`;

const HolaYessnamasteCom = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	position: absolute;
	left: 1370px;
	top: 137px;
`;

const CentroDeAyudaPreguntasFrecuentesPoliticasDePrivacidadTerminosYCondiciones = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	position: absolute;
	left: 1370px;
	top: 205px;
`;

const SíguemeEnMisRedes = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	position: absolute;
	left: 1368px;
	top: 409px;
`;

const YessNamaste2023 = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	position: absolute;
	left: 511px;
	top: 404px;
`;

const IconsaxLinearInstagram = styled.img`
	width: 30px;
	height: 30px;
	object-fit: cover;
	position: absolute;
	left: 1243px;
	top: 407px;
`;

const IconsaxLinearFacebook = styled.img`
	width: 28px;
	height: 28px;
	object-fit: cover;
	position: absolute;
	left: 1289px;
	top: 409px;
`;

const HolaCorazoncitoNamaste = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 56px;
	font-family: "Nib Pro", sans-serif;
	font-weight: 700;
	line-height: 124%;
	text-align: left;
	position: absolute;
	left: 452px;
	top: 408px;
`;

const QueremosEscucharteCuentanosSobreTíYLoQueAcontece = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 24px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	width: 358px;
	position: absolute;
	left: 452px;
	top: 641px;
`;

const Group18 = styled.div`
	width: 543px;
	height: 686px;
	position: absolute;
	left: 1096px;
	top: 247px;
`;

const Rectangle7 = styled.div`
	width: 543px;
	height: 686px;
	box-shadow: 0px 0px 20px  rgba(0, 0, 0, 0.15);
	background-color: white;
	border: solid 2px white;
	border-radius: 15px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Rectangle8 = styled.div`
	width: 456px;
	height: 255px;
	background-color: rgb(245, 245, 245);
	position: absolute;
	left: 43px;
	top: 332px;
`;

const Nombre = styled.span`
	color: rgb(150, 150, 150);
	text-overflow: ellipsis;
	font-size: 16px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 400;
	text-align: left;
	position: absolute;
	left: 83px;
	top: 117px;
`;

const CorreoElectrónico_0001 = styled.span`
	color: rgb(150, 150, 150);
	text-overflow: ellipsis;
	font-size: 16px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 400;
	text-align: left;
	position: absolute;
	left: 83px;
	top: 188px;
`;

const Teléfono = styled.span`
	color: rgb(150, 150, 150);
	text-overflow: ellipsis;
	font-size: 16px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 400;
	text-align: left;
	position: absolute;
	left: 83px;
	top: 261px;
`;

const CuentanosloTodo = styled.span`
	color: rgb(150, 150, 150);
	text-overflow: ellipsis;
	font-size: 16px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: left;
	position: absolute;
	left: 59px;
	top: 344px;
`;

const Line2 = styled.img`
	width: 456px;
	height: 0px;
	object-fit: cover;
	position: absolute;
	left: 43px;
	top: 145px;
`;

const Line3 = styled.img`
	width: 456px;
	height: 0px;
	object-fit: cover;
	position: absolute;
	left: 43px;
	top: 222px;
`;

const Line4 = styled.img`
	width: 456px;
	height: 0px;
	object-fit: cover;
	position: absolute;
	left: 43px;
	top: 292px;
`;

const Group16 = styled.div`
	width: 376px;
	height: 616px;
	position: absolute;
	left: 83px;
	top: 38px;
`;

const Rectangle2_0002 = styled.div`
	width: 241px;
	height: 50px;
	background-color: rgb(0, 140, 154);
	position: absolute;
	left: 67px;
	top: 566px;
`;

const Enviar = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 18px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
	width: 185px;
	min-height: 21px;
	position: absolute;
	left: 95px;
	top: 580px;
	height: 21px;
`;

const QueremosEscucharte = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 24px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
	width: 378px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Vector_0001 = styled.img`
	object-fit: cover;
	position: absolute;
	left: 56px;
	top: 114px;
	right: 473px;
	bottom: 552px;
`;

const Group1 = styled.div`
	width: 1933px;
	height: 100px;
	position: absolute;
	left: -13px;
	top: 0px;
`;

const Rectangle1 = styled.div`
	width: 1933px;
	height: 100px;
	box-shadow: 0px 4px 20px  rgba(0, 0, 0, 0.1);
	background-color: white;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Home = styled.span`
	color: rgb(39, 71, 88);
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	position: absolute;
	left: 1089px;
	top: 43px;
`;

const Cursos = styled.span`
	color: rgb(39, 71, 88);
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	position: absolute;
	left: 1210px;
	top: 43px;
`;

const SobreMí = styled.span`
	color: rgb(39, 71, 88);
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	position: absolute;
	left: 1360px;
	top: 43px;
`;

const Contacto_0002 = styled.span`
	color: rgb(0, 140, 154);
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	position: absolute;
	left: 1525px;
	top: 43px;
`;

const IconsaxLinearProfilecircle = styled.img`
	width: 36px;
	height: 36px;
	object-fit: cover;
	position: absolute;
	left: 1748px;
	top: 36px;
`;

const Logo = styled.div`
	width: 194px;
	height: 60px;
	overflow: hidden;
	position: absolute;
	left: 136px;
	top: 20px;
`;

const Group = styled.div`
	width: 194px;
	height: 60px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Group_0001 = styled.div`
	width: 80px;
	height: 60px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Group_0002 = styled.div`
	width: 71px;
	height: 60px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Vector_0002 = styled.svg`
	width: 13px;
	height: 24px;
	position: absolute;
	left: 28px;
	top: 28px;
	right: 30px;
	bottom: 8px;
`;

const Vector_0003 = styled.svg`
	width: 26px;
	height: 27px;
	position: absolute;
	left: 44px;
	top: 26px;
	right: -0px;
	bottom: 7px;
`;

const Group_0004 = styled.div`
	width: 35px;
	height: 60px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Vector_0004 = styled.svg`
	width: 35px;
	height: 60px;
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
`;

const Vector_0005 = styled.svg`
	width: 8px;
	height: 8px;
	position: absolute;
	left: 13px;
	top: 2px;
	right: 15px;
	bottom: 50px;
`;

const Group_0005 = styled.div`
	width: 2px;
	height: 43px;
	position: absolute;
	left: 79px;
	top: 16px;
`;

const Group_0006 = styled.div`
	width: 2px;
	height: 43px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Vector_0006 = styled.svg`
	width: 2px;
	height: 43px;
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
`;

const Vector_0007 = styled.svg`
	width: 104px;
	height: 20px;
	position: absolute;
	left: 90px;
	top: 30px;
	right: 0px;
	bottom: 10px;
`;

const Vector_0008 = styled.svg`
	width: 32px;
	height: 33px;
	position: absolute;
	left: 1821px;
	top: 36px;
	right: 80px;
	bottom: 31px;
`;

const IconsaxLinearCallreceived = styled.img`
	width: 24px;
	height: 24px;
	object-fit: cover;
	position: absolute;
	left: 1147px;
	top: 504px;
`;

const IconsaxLinearSms = styled.img`
	width: 24px;
	height: 24px;
	object-fit: cover;
	position: absolute;
	left: 1147px;
	top: 433px;
`;

