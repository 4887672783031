import React from "react";
import styled from "@emotion/styled";
export function Courses() {
  return (
    <RootWrapperCursos>
      <AcademíaDescubrete>
        ACADEMÍA
        <br />
        DESCUBRETE
      </AcademíaDescubrete>
      <Curso21
        src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/dbbd4d49-250c-4c85-a00f-d54237eaf250"
        alt="image of Curso21"
      />
      <Curso11
        src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/4660bb64-c439-4d0f-afe4-dc79f11a3bf8"
        alt="image of Curso11"
      />
      <Colectivo>COLECTIVO</Colectivo>
      <Sanación>SANACIÓN</Sanación>
      <_350>$350</_350>
      <_350_0001>$350</_350_0001>
      <Blog121
        src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/620a73f3-755e-45f5-9418-b1b6d0c23b07"
        alt="image of Blog121"
      />
      <Blog13
        src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/b25de2e9-b6f0-427c-b96e-b317c6bfae7c"
        alt="image of Blog13"
      />
      <Blog122
        src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/6538dbfc-652c-49b7-9d5e-fb40769fea35"
        alt="image of Blog122"
      />
      <Blog14
        src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/64169ac3-bef7-4878-981b-a4d3324e7a72"
        alt="image of Blog14"
      />
      <ElMayorProblemaDeLosSeresHumanosEsCreerQueNoDeberíanDeTenerProblemasPeroLosProblemasSonLosQueNosHacenCrecerLosProblemasSonLosQueEsculpenNuestraAlma>
        El mayor problema de los seres humanos es creer que no deberían de tener problemas. Pero los
        problemas son los que nos hacen crecer. Los problemas son los que esculpen nuestra alma”.
      </ElMayorProblemaDeLosSeresHumanosEsCreerQueNoDeberíanDeTenerProblemasPeroLosProblemasSonLosQueNosHacenCrecerLosProblemasSonLosQueEsculpenNuestraAlma>
      <ElMayorProblemaDeLosSeresHumanosEsCreerQueNoDeberíanDeTenerProblemasPeroLosProblemasSonLosQueNosHacenCrecerLosProblemasSonLosQueEsculpenNuestraAlma_0001>
        El mayor problema de los seres humanos es creer que no deberían de tener problemas. Pero los
        problemas son los que nos hacen crecer. Los problemas son los que esculpen nuestra alma”.
      </ElMayorProblemaDeLosSeresHumanosEsCreerQueNoDeberíanDeTenerProblemasPeroLosProblemasSonLosQueNosHacenCrecerLosProblemasSonLosQueEsculpenNuestraAlma_0001>
      <CuandoCaminamosSuavementeHaciaNuestroDestinoAbrazamosLosAprendizajesTantoDeLosMomentosCómodosComoIncomodosNosInteresamosEnObtenerYSobreTodoPracticarHerramientas>
        Cuando caminamos suavemente hacia nuestro destino: abrazamos los aprendizajes tanto de los
        momentos cómodos como incomodos, nos interesamos en obtener y sobre todo practicar
        herramientas...
      </CuandoCaminamosSuavementeHaciaNuestroDestinoAbrazamosLosAprendizajesTantoDeLosMomentosCómodosComoIncomodosNosInteresamosEnObtenerYSobreTodoPracticarHerramientas>
      <CuandoCaminamosSuavementeHaciaNuestroDestinoAbrazamosLosAprendizajesTantoDeLosMomentosCómodosComoIncomodosNosInteresamosEnObtenerYSobreTodoPracticarHerramientas_0001>
        Cuando caminamos suavemente hacia nuestro destino: abrazamos los aprendizajes tanto de los
        momentos cómodos como incomodos, nos interesamos en obtener y sobre todo practicar
        herramientas...
      </CuandoCaminamosSuavementeHaciaNuestroDestinoAbrazamosLosAprendizajesTantoDeLosMomentosCómodosComoIncomodosNosInteresamosEnObtenerYSobreTodoPracticarHerramientas_0001>
      <_4DeEnero2023>4 de Enero 2023</_4DeEnero2023>
      <_4DeEnero2023_0001>4 de Enero 2023</_4DeEnero2023_0001>
      <_4DeEnero2023_0002>4 de Enero 2023</_4DeEnero2023_0002>
      <_4DeEnero2023_0003>4 de Enero 2023</_4DeEnero2023_0003>
      <Ellipse3 />
      <Ellipse5 />
      <Ellipse4 />
      <Ellipse6 />
      <Ellipse7 />
      <CursosYProgramasGrupalesParaPoderConectarConTuYoInteriorAtreveteAAbrirTuCorazón>
        Cursos y programas grupales para poder conectar con tu yo interior, atrevete a abrir tu
        corazón...
      </CursosYProgramasGrupalesParaPoderConectarConTuYoInteriorAtreveteAAbrirTuCorazón>
      <EstamosEnfocadosEnAyudarALasPersonasAEncontrarLaPazInteriorYLaFelicidadEnSuVidaDiariaSiTeGustaLoQueEstamosHaciendoYQuieresApoyarnosConsideraHacerUnaDonaciónCualquierCantidadQuePuedasContribuirSeráMuyApreciada>
        Estamos enfocados en ayudar a las personas a encontrar la paz interior y la felicidad en su
        vida diaria. Si te gusta lo que estamos haciendo y quieres apoyarnos, considera hacer una
        donación cualquier cantidad que puedas contribuir será muy apreciada.
      </EstamosEnfocadosEnAyudarALasPersonasAEncontrarLaPazInteriorYLaFelicidadEnSuVidaDiariaSiTeGustaLoQueEstamosHaciendoYQuieresApoyarnosConsideraHacerUnaDonaciónCualquierCantidadQuePuedasContribuirSeráMuyApreciada>
      <TuGenerosidadNosAyudaráASeguirCreandoContenidoDeCalidadYaLlegarAMásCorazonesNamaste>
        Tu generosidad nos ayudará a seguir creando contenido de calidad <br />y a llegar a más
        corazones namaste.
      </TuGenerosidadNosAyudaráASeguirCreandoContenidoDeCalidadYaLlegarAMásCorazonesNamaste>
      <QueremosSeguirHaciendoLoQueAmamos>
        Queremos seguir haciendo lo que amamos
      </QueremosSeguirHaciendoLoQueAmamos>
      <PreparaTuTazaDeTéYTambiénCaféPorqueCadaJuevesEstaremosCompartiendoExperienciasYPensamientosEnGrupoParaPoderExpandirNuestr>
        Prepara tu taza de té y también café, porque cada jueves estaremos compartiendo experiencias
        y pensamientos en grupo para poder expandir nuestr
      </PreparaTuTazaDeTéYTambiénCaféPorqueCadaJuevesEstaremosCompartiendoExperienciasYPensamientosEnGrupoParaPoderExpandirNuestr>
      <_12SesionesDeMeditaciónParaQueDespejesTuMeteYTeEncuentresConTuVerdaderoSerRelajateYDejaFluirTusPensamientos>
        12 sesiones de meditación para que despejes tu mete y te encuentres con tu verdadero ser,
        relajate y deja fluir tus pensamientos.
      </_12SesionesDeMeditaciónParaQueDespejesTuMeteYTeEncuentresConTuVerdaderoSerRelajateYDejaFluirTusPensamientos>
      <TeSorprenderáLaMagiaQueLlevasDentro>
        Te sorprenderá la magia que llevas dentro
      </TeSorprenderáLaMagiaQueLlevasDentro>
      <NochesDeNamaste>noches de namaste</NochesDeNamaste>
      <Meditaton>meditaton</Meditaton>
      <Group3>
        <Rectangle2 />
        <ComprarAhora>COMPRAR AHORA</ComprarAhora>
      </Group3>
      <Group16>
        <Rectangle2 />
        <ComprarAhora>COMPRAR AHORA</ComprarAhora>
      </Group16>
      <VíaZoom>Vía zoom</VíaZoom>
      <VíaZoom_0001>Vía zoom</VíaZoom_0001>
      <MembresiaÚnica>Membresia única</MembresiaÚnica>
      <MembresiaÚnica_0001>Membresia única</MembresiaÚnica_0001>
      <MembresiaÚnica_0002>Membresia única</MembresiaÚnica_0002>
      <Group15>
        <Rectangle2_0002 />
        <AñadirAlCarrito>AÑADIR AL CARRITO</AñadirAlCarrito>
      </Group15>
      <Group17>
        <Rectangle2_0002 />
        <AñadirAlCarrito>AÑADIR AL CARRITO</AñadirAlCarrito>
      </Group17>
      <Image5
        src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/74b6fe7c-c9c3-43f8-b555-b17800a19a43"
        alt="image of Image5"
      />
      <Group14>
        <Rectangle6 />
        <Group13>
          <Group11>
            <Group12>
              <Rectangle2_0004 />
            </Group12>
            <Rectangle2_0005 />
            <CorreoElectrónico>Correo electrónico</CorreoElectrónico>
          </Group11>
          <IconsaxLinearSend>
            <Vector
              src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/f56f3923-6cb5-4499-af2c-d7c484c5ee3b"
              alt="image of Vector"
            />
          </IconsaxLinearSend>
        </Group13>
        <Line1 />
        <NamasteBlanco1
          src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/65e3f157-60a2-42b9-99fe-e8ef32fc359c"
          alt="image of NamasteBlanco1"
        />
        <AcademíaDescúbrete>academía descúbrete</AcademíaDescúbrete>
        <Contacto>contacto</Contacto>
        <NochesDeNamasteMeditaton>
          Noches de namaste
          <br />
          Meditaton
        </NochesDeNamasteMeditaton>
        <RecibeNuestrasPromocionesONoticiasAntesQueNadie>
          Recibe nuestras promociones
          <br />o noticias antes que nadie
        </RecibeNuestrasPromocionesONoticiasAntesQueNadie>
        <ÚneteAlTeam>Únete al team</ÚneteAlTeam>
        <HolaYessnamasteCom>hola@yessnamaste.com</HolaYessnamasteCom>
        <CentroDeAyudaPreguntasFrecuentesPoliticasDePrivacidadTerminosYCondiciones>
          Centro de ayuda
          <br />
          Preguntas frecuentes
          <br />
          Politicas de privacidad
          <br />
          Terminos y condiciones
        </CentroDeAyudaPreguntasFrecuentesPoliticasDePrivacidadTerminosYCondiciones>
        <SíguemeEnMisRedes>Sígueme en mis redes</SíguemeEnMisRedes>
        <YessNamaste2023>© Yess Namaste 2023</YessNamaste2023>
        <IconsaxLinearInstagram
          src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/dd9eff22-0594-4fd2-9006-00ed2541b59b"
          alt="icon"
        />
        <IconsaxLinearFacebook
          src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/47be1664-c200-4c3d-8217-8e3ebc4152e3"
          alt="icon"
        />
      </Group14>
      <MaskGroup>
        <MaskGroup_0001>
          <BreathtakingSceneryOfTheBlackSeaCoast20221115145352Utc1
            src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/f682289d-4785-424e-bc91-a1b8e3391d99"
            alt="image of BreathtakingSceneryOfTheBlackSeaCoast20221115145352Utc1"
          />
        </MaskGroup_0001>
      </MaskGroup>
    </RootWrapperCursos>
  );
}

const RootWrapperCursos = styled.div`
	min-height: 100vh;
	background-color: white;
	position: relative;
`;

const Rectangle4 = styled.div`
	width: 1933px;
	height: 632px;
	background-color: rgb(245, 245, 245);
	position: absolute;
	left: -6px;
	top: 100px;
`;

const Rectangle5 = styled.div`
	width: 1933px;
	height: 680px;
	background-color: rgba(188, 227, 201, 0.5);
	position: absolute;
	left: -6px;
	top: 2123px;
`;

const AcademíaDescubrete = styled.span`
	color: rgb(254, 158, 15);
	text-overflow: ellipsis;
	font-size: 72px;
	font-family: "Nib Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	text-transform: uppercase;
	position: absolute;
	left: 295px;
	top: 288px;
`;

const Curso21 = styled.img`
	width: 385px;
	height: 385px;
	object-fit: cover;
	position: absolute;
	left: 284px;
	top: 1598px;
`;

const Curso11 = styled.img`
	width: 385px;
	height: 385px;
	object-fit: cover;
	position: absolute;
	left: 284px;
	top: 1045px;
`;

const Colectivo = styled.span`
	color: rgb(254, 158, 15);
	text-overflow: ellipsis;
	font-size: 18px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	position: absolute;
	left: 757px;
	top: 1073px;
`;

const Sanación = styled.span`
	color: rgb(254, 158, 15);
	text-overflow: ellipsis;
	font-size: 18px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	position: absolute;
	left: 757px;
	top: 1633px;
`;

const _350 = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 42px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: center;
	position: absolute;
	left: 761px;
	top: 1267px;
`;

const _350_0001 = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 42px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: center;
	position: absolute;
	left: 761px;
	top: 1806px;
`;

const Blog121 = styled.img`
	width: 341px;
	height: 341px;
	object-fit: cover;
	position: absolute;
	left: 151px;
	top: 2981px;
`;

const Blog13 = styled.img`
	width: 341px;
	height: 341px;
	object-fit: cover;
	position: absolute;
	left: 557px;
	top: 2981px;
`;

const Blog122 = styled.img`
	width: 341px;
	height: 341px;
	object-fit: cover;
	position: absolute;
	left: 963px;
	top: 2981px;
`;

const Blog14 = styled.img`
	width: 341px;
	height: 341px;
	object-fit: cover;
	position: absolute;
	left: 1369px;
	top: 2981px;
`;

const ElMayorProblemaDeLosSeresHumanosEsCreerQueNoDeberíanDeTenerProblemasPeroLosProblemasSonLosQueNosHacenCrecerLosProblemasSonLosQueEsculpenNuestraAlma = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	width: 335px;
	min-height: 194px;
	position: absolute;
	left: 157px;
	top: 3361px;
	height: 194px;
`;

const ElMayorProblemaDeLosSeresHumanosEsCreerQueNoDeberíanDeTenerProblemasPeroLosProblemasSonLosQueNosHacenCrecerLosProblemasSonLosQueEsculpenNuestraAlma_0001 = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	width: 335px;
	min-height: 194px;
	position: absolute;
	left: 963px;
	top: 3361px;
	height: 194px;
`;

const CuandoCaminamosSuavementeHaciaNuestroDestinoAbrazamosLosAprendizajesTantoDeLosMomentosCómodosComoIncomodosNosInteresamosEnObtenerYSobreTodoPracticarHerramientas = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	width: 335px;
	min-height: 194px;
	position: absolute;
	left: 557px;
	top: 3361px;
	height: 194px;
`;

const CuandoCaminamosSuavementeHaciaNuestroDestinoAbrazamosLosAprendizajesTantoDeLosMomentosCómodosComoIncomodosNosInteresamosEnObtenerYSobreTodoPracticarHerramientas_0001 = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	width: 335px;
	min-height: 194px;
	position: absolute;
	left: 1363px;
	top: 3361px;
	height: 194px;
`;

const _4DeEnero2023 = styled.span`
	color: rgb(150, 150, 150);
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	position: absolute;
	left: 157px;
	top: 3593px;
`;

const _4DeEnero2023_0001 = styled.span`
	color: rgb(150, 150, 150);
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	position: absolute;
	left: 557px;
	top: 3593px;
`;

const _4DeEnero2023_0002 = styled.span`
	color: rgb(150, 150, 150);
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	position: absolute;
	left: 963px;
	top: 3593px;
`;

const _4DeEnero2023_0003 = styled.span`
	color: rgb(150, 150, 150);
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	position: absolute;
	left: 1368px;
	top: 3593px;
`;

const Ellipse3 = styled.div`
	width: 51px;
	height: 51px;
	background-color: rgb(245, 245, 245);
	border-radius: 51px / 51px;
	position: absolute;
	left: 1397px;
	top: 1112px;
`;

const Ellipse5 = styled.div`
	width: 51px;
	height: 51px;
	background-color: rgb(245, 245, 245);
	border-radius: 51px / 51px;
	position: absolute;
	left: 1397px;
	top: 1687px;
`;

const Ellipse4 = styled.div`
	width: 51px;
	height: 51px;
	background-color: rgb(245, 245, 245);
	border-radius: 51px / 51px;
	position: absolute;
	left: 1397px;
	top: 1181px;
`;

const Ellipse6 = styled.div`
	width: 51px;
	height: 51px;
	background-color: rgb(245, 245, 245);
	border-radius: 51px / 51px;
	position: absolute;
	left: 1397px;
	top: 1755px;
`;

const Ellipse7 = styled.div`
	width: 51px;
	height: 51px;
	background-color: rgb(245, 245, 245);
	border-radius: 51px / 51px;
	position: absolute;
	left: 1397px;
	top: 1823px;
`;

const CursosYProgramasGrupalesParaPoderConectarConTuYoInteriorAtreveteAAbrirTuCorazón = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 24px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: left;
	width: 486px;
	position: absolute;
	left: 295px;
	top: 455px;
`;

const EstamosEnfocadosEnAyudarALasPersonasAEncontrarLaPazInteriorYLaFelicidadEnSuVidaDiariaSiTeGustaLoQueEstamosHaciendoYQuieresApoyarnosConsideraHacerUnaDonaciónCualquierCantidadQuePuedasContribuirSeráMuyApreciada = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 26px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: center;
	width: 995px;
	position: absolute;
	left: 462px;
	top: 2378px;
`;

const TuGenerosidadNosAyudaráASeguirCreandoContenidoDeCalidadYaLlegarAMásCorazonesNamaste = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 24px;
	font-family: "Nib Pro", sans-serif;
	font-weight: 700;
	line-height: 36px;
	text-align: center;
	width: 1042px;
	position: absolute;
	left: 439px;
	top: 2676px;
`;

const QueremosSeguirHaciendoLoQueAmamos = styled.span`
	color: rgb(0, 140, 154);
	text-overflow: ellipsis;
	font-size: 42px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
	width: 745px;
	position: absolute;
	left: 588px;
	top: 2223px;
`;

const PreparaTuTazaDeTéYTambiénCaféPorqueCadaJuevesEstaremosCompartiendoExperienciasYPensamientosEnGrupoParaPoderExpandirNuestr = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 24px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: left;
	width: 532px;
	min-height: 94px;
	position: absolute;
	left: 757px;
	top: 1120px;
	height: 94px;
`;

const _12SesionesDeMeditaciónParaQueDespejesTuMeteYTeEncuentresConTuVerdaderoSerRelajateYDejaFluirTusPensamientos = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 24px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: left;
	width: 532px;
	min-height: 94px;
	position: absolute;
	left: 757px;
	top: 1680px;
	height: 94px;
`;

const TeSorprenderáLaMagiaQueLlevasDentro = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 24px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	text-transform: uppercase;
	width: 419px;
	min-height: 77px;
	position: absolute;
	left: 295px;
	top: 569px;
	height: 77px;
`;

const NochesDeNamaste = styled.span`
	color: rgb(0, 140, 154);
	text-overflow: ellipsis;
	font-size: 42px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	text-transform: uppercase;
	position: absolute;
	left: 284px;
	top: 912px;
`;

const Meditaton = styled.span`
	color: rgb(0, 140, 154);
	text-overflow: ellipsis;
	font-size: 42px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	text-transform: uppercase;
	position: absolute;
	left: 284px;
	top: 1515px;
`;

const Group3 = styled.div`
	width: 241px;
	height: 50px;
	position: absolute;
	left: 757px;
	top: 1343px;
`;

const Rectangle2 = styled.div`
	width: 241px;
	height: 50px;
	background-color: rgb(0, 140, 154);
	position: absolute;
	left: 0px;
	top: 0px;
`;

const ComprarAhora = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 18px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	text-transform: uppercase;
	width: 197px;
	min-height: 21px;
	position: absolute;
	left: 33px;
	top: 14px;
	height: 21px;
`;

const Group16 = styled.div`
	width: 241px;
	height: 50px;
	position: absolute;
	left: 757px;
	top: 1882px;
`;

const VíaZoom = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 18px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	position: absolute;
	left: 1467px;
	top: 1130px;
`;

const VíaZoom_0001 = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 18px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	position: absolute;
	left: 1467px;
	top: 1704px;
`;

const MembresiaÚnica = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 18px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	position: absolute;
	left: 1467px;
	top: 1770px;
`;

const MembresiaÚnica_0001 = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 18px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	position: absolute;
	left: 1467px;
	top: 1195px;
`;

const MembresiaÚnica_0002 = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 18px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	position: absolute;
	left: 1467px;
	top: 1838px;
`;

const Group15 = styled.div`
	width: 258px;
	height: 50px;
	position: absolute;
	left: 1008px;
	top: 1343px;
`;

const Rectangle2_0002 = styled.div`
	width: 258px;
	height: 50px;
	border: solid 2px rgb(0, 140, 154);
	position: absolute;
	left: 0px;
	top: 0px;
`;

const AñadirAlCarrito = styled.span`
	color: rgb(0, 140, 154);
	text-overflow: ellipsis;
	font-size: 18px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	text-transform: uppercase;
	width: 227px;
	min-height: 21px;
	position: absolute;
	left: 36px;
	top: 14px;
	height: 21px;
`;

const Group17 = styled.div`
	width: 258px;
	height: 50px;
	position: absolute;
	left: 1008px;
	top: 1882px;
`;

const Image5 = styled.img`
	width: 339px;
	height: 158px;
	object-fit: cover;
	position: absolute;
	left: 791px;
	top: 2502px;
`;

const Group14 = styled.div`
	width: 1920px;
	height: 466px;
	position: absolute;
	left: 0px;
	top: 3726px;
`;

const Rectangle6 = styled.div`
	width: 1920px;
	height: 466px;
	background-color: rgb(0, 140, 154);
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Group13 = styled.div`
	width: 344px;
	height: 48px;
	position: absolute;
	left: 511px;
	top: 222px;
`;

const Group11 = styled.div`
	width: 344px;
	height: 48px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Group12 = styled.div`
	width: 60px;
	height: 48px;
	position: absolute;
	left: 284px;
	top: -0px;
`;

const Rectangle2_0004 = styled.div`
	width: 60px;
	height: 48px;
	background-color: white;
	border-radius: 6px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Rectangle2_0005 = styled.div`
	width: 344px;
	height: 48px;
	border: solid 2px white;
	border-radius: 6px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const CorreoElectrónico = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: left;
	width: 235px;
	min-height: 20px;
	position: absolute;
	left: 31px;
	top: 10px;
	height: 20px;
`;

const IconsaxLinearSend = styled.div`
	width: 32px;
	height: 31px;
	overflow: hidden;
	position: absolute;
	left: 299px;
	top: 7px;
`;

const Vector = styled.img`
	object-fit: cover;
	position: absolute;
	left: 4px;
	top: 4px;
	right: 4px;
	bottom: 4px;
`;

const Line1 = styled.div`
	width: 1920px;
	height: 0px;
	border-top: solid 1px white;
	position: absolute;
	left: 0px;
	top: 375px;
`;

const NamasteBlanco1 = styled.img`
	width: 232px;
	height: 174px;
	object-fit: cover;
	position: absolute;
	left: 163px;
	top: 54px;
`;

const AcademíaDescúbrete = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 24px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: justify;
	text-transform: uppercase;
	position: absolute;
	left: 919px;
	top: 79px;
`;

const Contacto = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 24px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: justify;
	text-transform: uppercase;
	position: absolute;
	left: 1370px;
	top: 79px;
`;

const NochesDeNamasteMeditaton = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	position: absolute;
	left: 919px;
	top: 137px;
`;

const RecibeNuestrasPromocionesONoticiasAntesQueNadie = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	position: absolute;
	left: 511px;
	top: 137px;
`;

const ÚneteAlTeam = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 24px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: justify;
	text-transform: uppercase;
	position: absolute;
	left: 511px;
	top: 88px;
`;

const HolaYessnamasteCom = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	position: absolute;
	left: 1370px;
	top: 137px;
`;

const CentroDeAyudaPreguntasFrecuentesPoliticasDePrivacidadTerminosYCondiciones = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	position: absolute;
	left: 1370px;
	top: 205px;
`;

const SíguemeEnMisRedes = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	position: absolute;
	left: 1368px;
	top: 409px;
`;

const YessNamaste2023 = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	position: absolute;
	left: 511px;
	top: 404px;
`;

const IconsaxLinearInstagram = styled.img`
	width: 30px;
	height: 30px;
	object-fit: cover;
	position: absolute;
	left: 1243px;
	top: 407px;
`;

const IconsaxLinearFacebook = styled.img`
	width: 28px;
	height: 28px;
	object-fit: cover;
	position: absolute;
	left: 1289px;
	top: 409px;
`;

const MaskGroup = styled.div`
	width: 610px;
	height: 610px;
	position: absolute;
	left: 990px;
	top: 237px;
`;

const MaskGroup_0001 = styled.div`
	width: 610px;
	height: 610px;
	overflow: hidden;
	position: absolute;
	left: 0px;
	top: 0px;
	border-radius: 305px;
`;

const BreathtakingSceneryOfTheBlackSeaCoast20221115145352Utc1 = styled.img`
	width: 1112px;
	height: 834px;
	object-fit: cover;
	position: absolute;
	left: -331px;
	top: -176px;
`;

const Group1 = styled.div`
	width: 1933px;
	height: 100px;
	position: absolute;
	left: -13px;
	top: 0px;
`;

const Rectangle1 = styled.div`
	width: 1933px;
	height: 100px;
	box-shadow: 0px 4px 20px  rgba(0, 0, 0, 0.1);
	background-color: white;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Home = styled.span`
	color: rgb(39, 71, 88);
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	position: absolute;
	left: 1089px;
	top: 43px;
`;

const Cursos_0001 = styled.span`
	color: rgb(0, 140, 154);
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	position: absolute;
	left: 1210px;
	top: 43px;
`;

const SobreMí = styled.span`
	color: rgb(39, 71, 88);
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	position: absolute;
	left: 1360px;
	top: 43px;
`;

const Contacto_0001 = styled.span`
	color: rgb(39, 71, 88);
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	position: absolute;
	left: 1525px;
	top: 43px;
`;

const IconsaxLinearProfilecircle = styled.img`
	width: 36px;
	height: 36px;
	object-fit: cover;
	position: absolute;
	left: 1748px;
	top: 36px;
`;

const Logo = styled.div`
	width: 194px;
	height: 60px;
	overflow: hidden;
	position: absolute;
	left: 136px;
	top: 20px;
`;

const Group = styled.div`
	width: 194px;
	height: 60px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Group_0001 = styled.div`
	width: 80px;
	height: 60px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Group_0002 = styled.div`
	width: 71px;
	height: 60px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Vector_0001 = styled.svg`
	width: 13px;
	height: 24px;
	position: absolute;
	left: 28px;
	top: 28px;
	right: 30px;
	bottom: 8px;
`;

const Vector_0002 = styled.svg`
	width: 26px;
	height: 27px;
	position: absolute;
	left: 44px;
	top: 26px;
	right: -0px;
	bottom: 7px;
`;

const Group_0004 = styled.div`
	width: 35px;
	height: 60px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Vector_0003 = styled.svg`
	width: 35px;
	height: 60px;
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
`;

const Vector_0004 = styled.svg`
	width: 8px;
	height: 8px;
	position: absolute;
	left: 13px;
	top: 2px;
	right: 15px;
	bottom: 50px;
`;

const Group_0005 = styled.div`
	width: 2px;
	height: 43px;
	position: absolute;
	left: 79px;
	top: 16px;
`;

const Group_0006 = styled.div`
	width: 2px;
	height: 43px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Vector_0005 = styled.svg`
	width: 2px;
	height: 43px;
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
`;

const Vector_0006 = styled.svg`
	width: 104px;
	height: 20px;
	position: absolute;
	left: 90px;
	top: 30px;
	right: 0px;
	bottom: 10px;
`;

const Vector_0007 = styled.svg`
	width: 32px;
	height: 33px;
	position: absolute;
	left: 1821px;
	top: 36px;
	right: 80px;
	bottom: 31px;
`;
