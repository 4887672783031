import React from "react";
import styled from "@emotion/styled";

export function Home() {
    return (
      <div>
        <Banner1 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/d1f9f660-8d22-4931-a83a-022363cdf06e" alt="image of Banner1"/>
        <Group3>
          <Rectangle2/>
          <DescubreMas>
            DESCUBRE MÁS
          </DescubreMas>
        </Group3>
        <Group8>
          <Rectangle2/>
          <DescubreMas>
            DESCUBRE MÁS
          </DescubreMas>
        </Group8>
        <Group9>
          <Rectangle2_0002/>
          <MisPadres>
            mis padres
          </MisPadres>
        </Group9>
        <Group10>
          <Rectangle2_0002/>
          <MisMentores>
            mis mentores
          </MisMentores>
        </Group10>
        <AcademiaDescubrete>
          ACADEMÍA DESCUBRETE
        </AcademiaDescubrete>
        <MiFuenteDeInspiración>
          mi fuente <br/>
  de inspiración
        </MiFuenteDeInspiración>
        <Group2>
          <Curso21 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/2ee0983f-743c-4f7b-b8a9-953de5dd4179" alt="image of Curso21"/>
          <Curso22 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/8061efb0-9388-4c2c-8c0f-5d0d6a1b09e7" alt="image of Curso22"/>
          <Curso11 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/9ea339d0-e50a-47e7-a238-9bb1ac9a9ac3" alt="image of Curso11"/>
          <Curso12 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/b1476501-d0e4-4e71-88e8-f3f598295900" alt="image of Curso12"/>
        </Group2>
        <AcademiaContent>
          <Sanación>
            SANACIÓN
          </Sanación>
          <Sanación_0001>
            SANACIÓN
          </Sanación_0001>
          <Colectivo>
            COLECTIVO
          </Colectivo>
          <Colectivo_0001>
            COLECTIVO
          </Colectivo_0001>
          <NochesDeNamaste>
            NOCHES DE NAMASTE
          </NochesDeNamaste>
          <NochesDeNamaste_0001>
            NOCHES DE NAMASTE
          </NochesDeNamaste_0001>
          <NochesDeNamaste_0002>
            NOCHES DE NAMASTE
          </NochesDeNamaste_0002>
          <NochesDeNamaste_0003>
            NOCHES DE NAMASTE
          </NochesDeNamaste_0003>
          <_450>
            $450
          </_450>
          <_450_0001>
            $450
          </_450_0001>
          <_350>
            $350
          </_350>
          <_350_0001>
            $350
          </_350_0001>
        </AcademiaContent>
        <NocheContent>

        <Noche21 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/4669fdda-28c5-4170-8d7b-449d3bc1da10" alt="image of Noche21"/>
        <Noche31 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/9c45fbdf-af2f-4425-af51-926382478f5e" alt="image of Noche31"/>
        <Image1 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/5537a732-1ab7-4069-9f0c-87e250a6d192" alt="image of Image1"/>
        <Noche11 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/144ad218-df53-40d0-96d0-81e8667a46f7" alt="image of Noche11"/>
        <Noche3>
          <Rectangle4_0001/>
          <Rectangle5_0001/>
          <_3NocheDeNamaste>
            3° NOCHE DE NAMASTE
          </_3NocheDeNamaste>
          <VivirLaExperiencia>
            VIVIR LA EXPERIENCIA
          </VivirLaExperiencia>
          <UnaLuzAlimentaALaOtra>
            “UNA LUZ ALIMENTA A LA OTRA”
          </UnaLuzAlimentaALaOtra>
        </Noche3>
        <Noche3_0001>
          <Rectangle4_0001/>
          <Rectangle5_0001/>
          <_2NocheDeNamaste>
            2° NOCHE DE NAMASTE
          </_2NocheDeNamaste>
          <VivirLaExperiencia>
            VIVIR LA EXPERIENCIA
          </VivirLaExperiencia>
          <LaRaízDeMisTemores>
            “LA RAÍZ DE MIS TEMORES”
          </LaRaízDeMisTemores>
        </Noche3_0001>
        <Noche3_0002>
          <Rectangle4_0001/>
          <Rectangle5_0001/>
          <_1NocheDeNamaste>
            1° NOCHE DE NAMASTE
          </_1NocheDeNamaste>
          <VivirLaExperiencia>
            VIVIR LA EXPERIENCIA
          </VivirLaExperiencia>
          <ComienzaDeBuenas>
            “Comienza de buenas”
          </ComienzaDeBuenas>
        </Noche3_0002>
        </NocheContent>
        <VideoContent>
        <Group4>
          <Rectangle2_0004/>
          <Suscribirme>
            suscribirme
          </Suscribirme>
        </Group4>
        <Image2 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/9367f82f-a1d5-490e-b16f-89a043bfabd8" alt="image of Image2"/>
        <Image3 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/6d45330c-04d3-4acf-9483-6d148b5fb701" alt="image of Image3"/>
        <YessYoutubeColor1 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/36edc1cc-0510-41dd-97d8-2cec1a0a5222" alt="image of YessYoutubeColor1"/>
        <AquíEncontrarásVideosSemanalesParaPoderExpandirTuConsciencia>
          Aquí encontrarás videos semanales para poder expandir tu consciencia
        </AquíEncontrarásVideosSemanalesParaPoderExpandirTuConsciencia>
        <Image4 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/bea27dd5-24c8-4847-8139-07427d3cf58d" alt="image of Image4"/>
        <IconsaxBulkPlaycircle>
          <Vector xmlns="http://www.w3.org/2000/svg">
            <path fill="white" d="M37.5 74.1667C58.2113 74.1667 75 57.5645 75 37.0833C75 16.6022 58.2113 0 37.5 0C16.7888 0 0 16.6022 0 37.0833C0 57.5645 16.7888 74.1667 37.5 74.1667Z"/>
          </Vector>
          <Vector1 xmlns="http://www.w3.org/2000/svg">
            <path fill="white" d="M0 36.4123L0 0L28.2603 18.3693L0 36.4123Z"/>
          </Vector1>
        </IconsaxBulkPlaycircle>
        <IconsaxBulkPlaycircle_0001>
          <Vector xmlns="http://www.w3.org/2000/svg">
            <path fill="white" d="M37.5 74.1667C58.2113 74.1667 75 57.5645 75 37.0833C75 16.6022 58.2113 0 37.5 0C16.7888 0 0 16.6022 0 37.0833C0 57.5645 16.7888 74.1667 37.5 74.1667Z"/>
          </Vector>
          <Vector1 xmlns="http://www.w3.org/2000/svg">
            <path fill="white" d="M0 36.4123L0 0L28.2603 18.3693L0 36.4123Z"/>
          </Vector1>
        </IconsaxBulkPlaycircle_0001>
        <IconsaxBulkPlaycircle_0002>
          <Vector xmlns="http://www.w3.org/2000/svg">
            <path fill="white" d="M37.5 74.1667C58.2113 74.1667 75 57.5645 75 37.0833C75 16.6022 58.2113 0 37.5 0C16.7888 0 0 16.6022 0 37.0833C0 57.5645 16.7888 74.1667 37.5 74.1667Z"/>
          </Vector>
          <Vector1 xmlns="http://www.w3.org/2000/svg">
            <path fill="white" d="M0 36.4123L0 0L28.2603 18.3693L0 36.4123Z"/>
          </Vector1>
        </IconsaxBulkPlaycircle_0002>
        <Group5>
          <Ellipse1/>
          <YessNamaste>
            @YessNamaste
          </YessNamaste>
        </Group5>
        <Group6>
          <Ellipse1/>
          <YessNamaste>
            @YessNamaste
          </YessNamaste>
        </Group6>
        <Group7>
          <Ellipse1/>
          <YessNamaste>
            @YessNamaste
          </YessNamaste>
        </Group7>
        </VideoContent>

        <Buda1 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/b90e197c-50ed-4485-9f02-0deaa406cd01" alt="image of Buda1"/>
        <LoremIpsumDolorSitAmetConsecteturAdipiscingElitNullaEgetDolorUltriciesConvallisErosSitAmetRhoncusAntePhasellusInMagnaAIpsumVestibulumMaximusUtMattisTortorVelLoremFringillaHendreritSedMalesuadaTempusAnteNonSodalesElitCondimentumNonQuisqueOdioFelisLuctusVelScelerisqueInPharetraAcEratSedFacilisisLobortisElementumSuspendissePotenti>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget dolor ultricies, convallis eros sit amet, rhoncus ante. Phasellus in magna a ipsum vestibulum maximus. Ut mattis tortor vel lorem fringilla hendrerit. Sed malesuada tempus ante, non sodales elit condimentum non. Quisque odio felis, luctus vel scelerisque in, pharetra ac erat. Sed facilisis lobortis elementum. Suspendisse potenti.
        </LoremIpsumDolorSitAmetConsecteturAdipiscingElitNullaEgetDolorUltriciesConvallisErosSitAmetRhoncusAntePhasellusInMagnaAIpsumVestibulumMaximusUtMattisTortorVelLoremFringillaHendreritSedMalesuadaTempusAnteNonSodalesElitCondimentumNonQuisqueOdioFelisLuctusVelScelerisqueInPharetraAcEratSedFacilisisLobortisElementumSuspendissePotenti>
        <Blog121 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/d5755a8b-8472-4b0e-bcf8-11148192055f" alt="image of Blog121"/>
        <Blog13 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/40090e8c-f4f6-4c4b-b7a3-415890c2e548" alt="image of Blog13"/>
        <Blog122 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/a4ef3977-c759-47b3-a936-a08aff2f857e" alt="image of Blog122"/>
        <Blog14 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/e3f713f1-d65d-4e11-9f04-d7b999c2c631" alt="image of Blog14"/>
        <ElMayorProblemaDeLosSeresHumanosEsCreerQueNoDeberíanDeTenerProblemasPeroLosProblemasSonLosQueNosHacenCrecerLosProblemasSonLosQueEsculpenNuestraAlma>
          El mayor problema de los seres humanos es creer que no deberían de tener problemas. Pero los problemas son los que nos hacen crecer. Los problemas son los que esculpen nuestra alma”.
        </ElMayorProblemaDeLosSeresHumanosEsCreerQueNoDeberíanDeTenerProblemasPeroLosProblemasSonLosQueNosHacenCrecerLosProblemasSonLosQueEsculpenNuestraAlma>
        <ElMayorProblemaDeLosSeresHumanosEsCreerQueNoDeberíanDeTenerProblemasPeroLosProblemasSonLosQueNosHacenCrecerLosProblemasSonLosQueEsculpenNuestraAlma_0001>
          El mayor problema de los seres humanos es creer que no deberían de tener problemas. Pero los problemas son los que nos hacen crecer. Los problemas son los que esculpen nuestra alma”.
        </ElMayorProblemaDeLosSeresHumanosEsCreerQueNoDeberíanDeTenerProblemasPeroLosProblemasSonLosQueNosHacenCrecerLosProblemasSonLosQueEsculpenNuestraAlma_0001>
        <CuandoCaminamosSuavementeHaciaNuestroDestinoAbrazamosLosAprendizajesTantoDeLosMomentosCómodosComoIncomodosNosInteresamosEnObtenerYSobreTodoPracticarHerramientas>
          Cuando caminamos suavemente hacia nuestro destino: abrazamos los aprendizajes tanto de los momentos cómodos como incomodos, nos interesamos en obtener y sobre todo practicar herramientas...
        </CuandoCaminamosSuavementeHaciaNuestroDestinoAbrazamosLosAprendizajesTantoDeLosMomentosCómodosComoIncomodosNosInteresamosEnObtenerYSobreTodoPracticarHerramientas>
        <CuandoCaminamosSuavementeHaciaNuestroDestinoAbrazamosLosAprendizajesTantoDeLosMomentosCómodosComoIncomodosNosInteresamosEnObtenerYSobreTodoPracticarHerramientas_0001>
          Cuando caminamos suavemente hacia nuestro destino: abrazamos los aprendizajes tanto de los momentos cómodos como incomodos, nos interesamos en obtener y sobre todo practicar herramientas...
        </CuandoCaminamosSuavementeHaciaNuestroDestinoAbrazamosLosAprendizajesTantoDeLosMomentosCómodosComoIncomodosNosInteresamosEnObtenerYSobreTodoPracticarHerramientas_0001>
        <_4DeEnero2023>
          4 de Enero 2023
        </_4DeEnero2023>
        <_4DeEnero2023_0001>
          4 de Enero 2023
        </_4DeEnero2023_0001>
        <_4DeEnero2023_0002>
          4 de Enero 2023
        </_4DeEnero2023_0002>
        <_4DeEnero2023_0003>
          4 de Enero 2023
        </_4DeEnero2023_0003>
        <Group14>
          <Rectangle6/>
          <Group13>
            <Group11>
              <Group12>
                <Rectangle2_0005/>
              </Group12>
              <Rectangle2_0006/>
              <CorreoElectrónico>
                Correo electrónico
              </CorreoElectrónico>
            </Group11>
            <IconsaxLinearSend>
              <Vector_0003 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/b99ecb2f-ae17-40fa-a06d-d91db7c229f4" alt="image of Vector"/>
            </IconsaxLinearSend>
          </Group13>
          <Line1/>
          <NamasteBlanco1 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/c720073b-ad08-468a-b70d-46d30fdaf6e1" alt="image of NamasteBlanco1"/>
          <AcademíaDescúbrete>
            academía descúbrete
          </AcademíaDescúbrete>
          <Contacto>
            contacto
          </Contacto>
          <NochesDeNamasteMeditaton>
            Noches de namaste<br/>
  Meditaton
          </NochesDeNamasteMeditaton>
          <RecibeNuestrasPromocionesONoticiasAntesQueNadie>
            Recibe nuestras promociones<br/>
  o noticias antes que nadie
          </RecibeNuestrasPromocionesONoticiasAntesQueNadie>
          <ÚneteAlTeam>
            Únete al team
          </ÚneteAlTeam>
          <HolaYessnamasteCom>
            hola@yessnamaste.com
          </HolaYessnamasteCom>
          <CentroDeAyudaPreguntasFrecuentesPoliticasDePrivacidadTerminosYCondiciones>
            Centro de ayuda
  <br/>
  Preguntas frecuentes
  <br/>
  Politicas de privacidad
  <br/>
  Terminos y condiciones
          </CentroDeAyudaPreguntasFrecuentesPoliticasDePrivacidadTerminosYCondiciones>
          <SíguemeEnMisRedes>
            Sígueme en mis redes
          </SíguemeEnMisRedes>
          <YessNamaste2023>
            © Yess Namaste 2023 
          </YessNamaste2023>
          <IconsaxLinearInstagram src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/37ee0f61-bf84-4f97-87f6-9de502ec502a" alt="icon"/>
          <IconsaxLinearFacebook src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/1ca86b5c-7a4b-4afa-a9f0-eeb60f03cadf" alt="icon"/>
        </Group14>
        <IconsaxLinearSend_0001 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/5cedd676-3a9f-46d6-ac00-d0339bd8f8e1" alt="icon"/>
      </div>
    )
  }

  const VideoContent = styled.div`
  left: -122px;
  position: relative;
`;
  
 const AcademiaContent = styled.div`
  left: -122px;
  position: relative;
`;
  
  const NocheContent = styled.div`
    left: -122px;
    position: relative;
  `;
  
  const Rectangle4 = styled.div`
      width: 1920px;
      height: 900px;
      background-color: rgb(245, 245, 245);
      position: absolute;
      left: 0px;
      top: 1805px;
  `;
  
  const Banner1 = styled.img`
      width: 1920px;
      height: 850px;
      object-fit: cover;
      position: absolute;
      left: 0px;
      top: 100px;
  `;
  
  const Group3 = styled.div`
      width: 339px;
      height: 70px;
      position: absolute;
      left: 846px;
      top: 683px;
  `;
  
  const Rectangle2 = styled.div`
      width: 339px;
      height: 70px;
      background-color: white;
      position: absolute;
      left: 0px;
      top: 0px;
      cursor:pointer;
  `;
  
  const DescubreMas = styled.span`
      color: rgb(39, 71, 88);
      text-overflow: ellipsis;
      font-size: 24px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: left;
      text-transform: uppercase;
      position: absolute;
      left: 61px;
      top: 24px;
  `;
  
  const Group8 = styled.div`
      width: 339px;
      height: 70px;
      position: absolute;
      left: 798px;
      top: 4427px;
  `;
  
  const Group9 = styled.div`
      width: 339px;
      height: 70px;
      position: absolute;
      left: 798px;
      top: 4302px;
  `;
  
  const Rectangle2_0002 = styled.div`
      width: 339px;
      height: 70px;
      border: solid 3px rgb(0, 140, 154);
      position: absolute;
      left: 0px;
      top: 0px;
  `;
  
  const MisPadres = styled.span`
      color: rgb(0, 140, 154);
      text-overflow: ellipsis;
      font-size: 24px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: left;
      text-transform: uppercase;
      position: absolute;
      left: 89px;
      top: 20px;
  `;
  
  const Group10 = styled.div`
      width: 339px;
      height: 70px;
      position: absolute;
      left: 1132px;
      top: 4302px;
  `;
  
  const MisMentores = styled.span`
      color: rgb(0, 140, 154);
      text-overflow: ellipsis;
      font-size: 24px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: left;
      text-transform: uppercase;
      position: absolute;
      left: 69px;
      top: 20px;
  `;
  
  const Group4 = styled.div`
      width: 281px;
      height: 70px;
      position: absolute;
      left: 144px;
      top: 2356px;
  `;
  
  const Rectangle2_0004 = styled.div`
      width: 281px;
      height: 70px;
      background-color: rgb(0, 140, 154);
      position: absolute;
      left: 0px;
      top: 0px;
  `;
  
  const Suscribirme = styled.span`
      color: white;
      text-overflow: ellipsis;
      font-size: 24px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: left;
      text-transform: uppercase;
      position: absolute;
      left: 43px;
      top: 24px;
  `;
  
  const AcademiaDescubrete = styled.span`
    color: rgb(254, 158, 15);
    text-overflow: ellipsis;
    font-size: 3em;
    font-family: "Sweet Sans Pro",sans-serif;
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
    position: absolute;
    left: 583px;
    top: 1041px;
    min-width: 700px;
  `;
  
  const MiFuenteDeInspiración = styled.span`
      color: rgb(39, 71, 88);
      text-overflow: ellipsis;
      font-size: 42px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: left;
      text-transform: uppercase;
      position: absolute;
      left: 798px;
      top: 3911px;
  `;
  
  const Group2 = styled.div`
    width: 100%;
    height: 385px;
    position: absolute;
    left: 10px;
    top: 1178px;
  `;
  
  const Curso21 = styled.img`
      width: 385px;
      height: 385px;
      object-fit: cover;
      position: absolute;
      left: 0px;
      top: 0px;
  `;
  
  const Curso22 = styled.img`
      width: 385px;
      height: 385px;
      object-fit: cover;
      position: absolute;
      left: 860px;
      top: 0px;
  `;
  
  const Curso11 = styled.img`
      width: 385px;
      height: 385px;
      object-fit: cover;
      position: absolute;
      left: 430px;
      top: 0px;
  `;
  
  const Curso12 = styled.img`
      width: 385px;
      height: 385px;
      object-fit: cover;
      position: absolute;
      left: 1290px;
      top: 0px;
  `;
  
  const Sanación = styled.span`
      color: rgb(254, 158, 15);
      text-overflow: ellipsis;
      font-size: 18px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: left;
      position: absolute;
      left: 258px;
      top: 1599px;
  `;
  
  const Sanación_0001 = styled.span`
      color: rgb(254, 158, 15);
      text-overflow: ellipsis;
      font-size: 18px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: left;
      position: absolute;
      left: 1118px;
      top: 1599px;
  `;
  
  const Colectivo = styled.span`
      color: rgb(254, 158, 15);
      text-overflow: ellipsis;
      font-size: 18px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: left;
      position: absolute;
      left: 688px;
      top: 1599px;
  `;
  
  const Colectivo_0001 = styled.span`
      color: rgb(254, 158, 15);
      text-overflow: ellipsis;
      font-size: 18px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: left;
      position: absolute;
      left: 1548px;
      top: 1599px;
  `;
  
  const NochesDeNamaste = styled.span`
      color: black;
      text-overflow: ellipsis;
      font-size: 24px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: center;
      position: absolute;
      left: 171px;
      top: 1635px;
      min-width: 322px;
  `;
  
  const NochesDeNamaste_0001 = styled.span`
      color: black;
      text-overflow: ellipsis;
      font-size: 24px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: center;
      position: absolute;
      left: 1032px;
      top: 1635px;
      min-width: 322px;
  `;
  
  const NochesDeNamaste_0002 = styled.span`
      color: black;
      text-overflow: ellipsis;
      font-size: 24px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: center;
      position: absolute;
      left: 601px;
      top: 1635px;
      min-width: 322px;
  `;
  
  const NochesDeNamaste_0003 = styled.span`
      color: black;
      text-overflow: ellipsis;
      font-size: 24px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: center;
      position: absolute;
      left: 1462px;
      top: 1635px;
      min-width: 322px;
  `;
  
  const _450 = styled.span`
      color: black;
      text-overflow: ellipsis;
      font-size: 42px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: center;
      position: absolute;
      left: 255px;
      top: 1678px;
  `;
  
  const _450_0001 = styled.span`
      color: black;
      text-overflow: ellipsis;
      font-size: 42px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: center;
      position: absolute;
      left: 1116px;
      top: 1678px;
  `;
  
  const _350 = styled.span`
      color: black;
      text-overflow: ellipsis;
      font-size: 42px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: center;
      position: absolute;
      left: 686px;
      top: 1678px;
  `;
  
  const _350_0001 = styled.span`
      color: black;
      text-overflow: ellipsis;
      font-size: 42px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: center;
      position: absolute;
      left: 1547px;
      top: 1678px;
  `;
  
  const Noche21 = styled.img`
      width: 533px;
      height: 578px;
      object-fit: cover;
      position: absolute;
      left: 693px;
      top: 2794px;
  `;
  
  const Noche31 = styled.img`
      width: 533px;
      height: 578px;
      object-fit: cover;
      position: absolute;
      left: 1280px;
      top: 2794px;
  `;
  
  const Image1 = styled.img`
      width: 404px;
      height: 281px;
      object-fit: cover;
      position: absolute;
      left: -42px;
      top: -659px;
  `;
  
  const Noche11 = styled.img`
      width: 533px;
      height: 578px;
      object-fit: cover;
      position: absolute;
      left: 107px;
      top: 2794px;
  `;
  
  const Noche3 = styled.div`
      width: 467px;
      height: 291px;
      position: absolute;
      left: 135px;
      top: 3230px;
  `;
  
  const Rectangle4_0001 = styled.div`
      width: 467px;
      height: 291px;
      background-color: white;
      position: absolute;
      left: 0px;
      top: 0px;
  `;
  
  const Rectangle5_0001 = styled.div`
      width: 467px;
      height: 80px;
      background-color: rgb(0, 140, 154);
      position: absolute;
      left: 0px;
      top: 207px;
  `;
  
  const _3NocheDeNamaste = styled.span`
      color: rgb(0, 140, 154);
      text-overflow: ellipsis;
      font-size: 36px;
      font-family: "Nib Pro", sans-serif;
      font-weight: 700;
      text-align: center;
      text-transform: lowercase;
      position: absolute;
      left: 40px;
      top: 61px;
  `;
  
  const VivirLaExperiencia = styled.span`
      color: white;
      text-overflow: ellipsis;
      font-size: 24px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      position: absolute;
      left: 85px;
      top: 232px;
  `;
  
  const UnaLuzAlimentaALaOtra = styled.span`
      color: black;
      text-overflow: ellipsis;
      font-size: 18px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: center;
      position: absolute;
      left: 77px;
      top: 111px;
  `;
  
  const Noche3_0001 = styled.div`
      width: 467px;
      height: 291px;
      position: absolute;
      left: 727px;
      top: 3230px;
  `;
  
  const _2NocheDeNamaste = styled.span`
      color: rgb(0, 140, 154);
      text-overflow: ellipsis;
      font-size: 36px;
      font-family: "Nib Pro", sans-serif;
      font-weight: 700;
      text-align: center;
      text-transform: lowercase;
      position: absolute;
      left: 39px;
      top: 61px;
  `;
  
  const LaRaízDeMisTemores = styled.span`
      color: black;
      text-overflow: ellipsis;
      font-size: 18px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: center;
      position: absolute;
      left: 100px;
      top: 111px;
  `;
  
  const Noche3_0002 = styled.div`
      width: 467px;
      height: 291px;
      position: absolute;
      left: 1313px;
      top: 3230px;
  `;
  
  const _1NocheDeNamaste = styled.span`
      color: rgb(0, 140, 154);
      text-overflow: ellipsis;
      font-size: 36px;
      font-family: "Nib Pro", sans-serif;
      font-weight: 700;
      text-align: center;
      text-transform: lowercase;
      position: absolute;
      left: 43px;
      top: 61px;
  `;
  
  const ComienzaDeBuenas = styled.span`
      color: black;
      text-overflow: ellipsis;
      font-size: 18px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      position: absolute;
      left: 112px;
      top: 111px;
  `;
  
  const Image2 = styled.img`
      width: 384px;
      height: 690px;
      object-fit: cover;
      position: absolute;
      left: 552px;
      top: 1882px;
  `;
  
  const Image3 = styled.img`
      width: 385px;
      height: 695px;
      object-fit: cover;
      position: absolute;
      left: 982px;
      top: 1882px;
  `;
  
  const YessYoutubeColor1 = styled.img`
      width: 300px;
      height: 204px;
      object-fit: cover;
      position: absolute;
      left: 125px;
      top: 2025px;
  `;
  
  const AquíEncontrarásVideosSemanalesParaPoderExpandirTuConsciencia = styled.span`
      color: black;
      text-overflow: ellipsis;
      font-size: 21px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 500;
      text-align: left;
      width: 281px;
      min-height: 86px;
      position: absolute;
      left: 144px;
      top: 2256px;
      height: 86px;
  `;
  
  const Image4 = styled.img`
      width: 410px;
      height: 695px;
      object-fit: cover;
      position: absolute;
      left: 1400px;
      top: 1882px;
  `;
  
  const IconsaxBulkPlaycircle = styled.div`
      width: 90px;
      height: 89px;
      overflow: hidden;
      position: absolute;
      left: 719px;
      top: 2167px;
  `;
  
  const Vector = styled.svg`
      width: 75px;
      height: 74px;
      position: absolute;
      left: 7px;
      top: 7px;
      right: 8px;
      bottom: 7px;
      opacity: 0.4;
  `;
  
  const Vector1 = styled.svg`
      width: 28px;
      height: 36px;
      position: absolute;
      left: 36px;
      top: 27px;
      right: 26px;
      bottom: 25px;
  `;
  
  const IconsaxBulkPlaycircle_0001 = styled.div`
      width: 90px;
      height: 89px;
      overflow: hidden;
      position: absolute;
      left: 1130px;
      top: 2167px;
  `;
  
  const IconsaxBulkPlaycircle_0002 = styled.div`
      width: 90px;
      height: 89px;
      overflow: hidden;
      position: absolute;
      left: 1572px;
      top: 2167px;
  `;
  
  const Group5 = styled.div`
      width: 212px;
      height: 49px;
      position: absolute;
      left: 570px;
      top: 1904px;
  `;
  
  const Ellipse1 = styled.div`
      width: 49px;
      height: 49px;
      background-color: rgb(217, 217, 217);
      border-radius: 49px / 49px;
      position: absolute;
      left: 0px;
      top: 0px;
  `;
  
  const YessNamaste = styled.span`
      color: white;
      text-overflow: ellipsis;
      font-size: 18px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 500;
      text-align: left;
      width: 149px;
      min-height: 23px;
      position: absolute;
      left: 63px;
      top: 13px;
      height: 23px;
  `;
  
  const Group6 = styled.div`
      width: 212px;
      height: 49px;
      position: absolute;
      left: 1001px;
      top: 1904px;
  `;
  
  const Group7 = styled.div`
      width: 212px;
      height: 49px;
      position: absolute;
      left: 1440px;
      top: 1904px;
  `;
  
  const Buda1 = styled.img`
      width: 542px;
      height: 813px;
      object-fit: cover;
      position: absolute;
      left: 151px;
      top: 3835px;
  `;
  
  const LoremIpsumDolorSitAmetConsecteturAdipiscingElitNullaEgetDolorUltriciesConvallisErosSitAmetRhoncusAntePhasellusInMagnaAIpsumVestibulumMaximusUtMattisTortorVelLoremFringillaHendreritSedMalesuadaTempusAnteNonSodalesElitCondimentumNonQuisqueOdioFelisLuctusVelScelerisqueInPharetraAcEratSedFacilisisLobortisElementumSuspendissePotenti = styled.span`
      color: rgb(39, 71, 88);
      text-overflow: ellipsis;
      font-size: 21px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: left;
      width: 791px;
      min-height: 162px;
      position: absolute;
      left: 798px;
      top: 4092px;
      height: 162px;
  `;
  
  const Blog121 = styled.img`
      width: 341px;
      height: 341px;
      object-fit: cover;
      position: absolute;
      left: 151px;
      top: 4835px;
  `;
  
  const Blog13 = styled.img`
      width: 341px;
      height: 341px;
      object-fit: cover;
      position: absolute;
      left: 557px;
      top: 4835px;
  `;
  
  const Blog122 = styled.img`
      width: 341px;
      height: 341px;
      object-fit: cover;
      position: absolute;
      left: 963px;
      top: 4835px;
  `;
  
  const Blog14 = styled.img`
      width: 341px;
      height: 341px;
      object-fit: cover;
      position: absolute;
      left: 1369px;
      top: 4835px;
  `;
  
  const ElMayorProblemaDeLosSeresHumanosEsCreerQueNoDeberíanDeTenerProblemasPeroLosProblemasSonLosQueNosHacenCrecerLosProblemasSonLosQueEsculpenNuestraAlma = styled.span`
      color: black;
      text-overflow: ellipsis;
      font-size: 21px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 400;
      text-align: justify;
      width: 335px;
      min-height: 194px;
      position: absolute;
      left: 157px;
      top: 5215px;
      height: 194px;
  `;
  
  const ElMayorProblemaDeLosSeresHumanosEsCreerQueNoDeberíanDeTenerProblemasPeroLosProblemasSonLosQueNosHacenCrecerLosProblemasSonLosQueEsculpenNuestraAlma_0001 = styled.span`
      color: black;
      text-overflow: ellipsis;
      font-size: 21px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 400;
      text-align: justify;
      width: 335px;
      min-height: 194px;
      position: absolute;
      left: 963px;
      top: 5215px;
      height: 194px;
  `;
  
  const CuandoCaminamosSuavementeHaciaNuestroDestinoAbrazamosLosAprendizajesTantoDeLosMomentosCómodosComoIncomodosNosInteresamosEnObtenerYSobreTodoPracticarHerramientas = styled.span`
      color: black;
      text-overflow: ellipsis;
      font-size: 21px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 400;
      text-align: justify;
      width: 335px;
      min-height: 194px;
      position: absolute;
      left: 557px;
      top: 5215px;
      height: 194px;
  `;
  
  const CuandoCaminamosSuavementeHaciaNuestroDestinoAbrazamosLosAprendizajesTantoDeLosMomentosCómodosComoIncomodosNosInteresamosEnObtenerYSobreTodoPracticarHerramientas_0001 = styled.span`
      color: black;
      text-overflow: ellipsis;
      font-size: 21px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 400;
      text-align: justify;
      width: 335px;
      min-height: 194px;
      position: absolute;
      left: 1363px;
      top: 5215px;
      height: 194px;
  `;
  
  const _4DeEnero2023 = styled.span`
      color: rgb(150, 150, 150);
      text-overflow: ellipsis;
      font-size: 21px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 500;
      text-align: justify;
      position: absolute;
      left: 157px;
      top: 5447px;
  `;
  
  const _4DeEnero2023_0001 = styled.span`
      color: rgb(150, 150, 150);
      text-overflow: ellipsis;
      font-size: 21px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 500;
      text-align: justify;
      position: absolute;
      left: 557px;
      top: 5447px;
  `;
  
  const _4DeEnero2023_0002 = styled.span`
      color: rgb(150, 150, 150);
      text-overflow: ellipsis;
      font-size: 21px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 500;
      text-align: justify;
      position: absolute;
      left: 963px;
      top: 5447px;
  `;
  
  const _4DeEnero2023_0003 = styled.span`
      color: rgb(150, 150, 150);
      text-overflow: ellipsis;
      font-size: 21px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 500;
      text-align: justify;
      position: absolute;
      left: 1368px;
      top: 5447px;
  `;
  
  const Group14 = styled.div`
      width: 1920px;
      height: 466px;
      position: absolute;
      left: 0px;
      top: 5565px;
  `;
  
  const Rectangle6 = styled.div`
      width: 1920px;
      height: 466px;
      background-color: rgb(0, 140, 154);
      position: absolute;
      left: 0px;
      top: 0px;
  `;
  
  const Group13 = styled.div`
      width: 344px;
      height: 48px;
      position: absolute;
      left: 511px;
      top: 222px;
  `;
  
  const Group11 = styled.div`
      width: 344px;
      height: 48px;
      position: absolute;
      left: 0px;
      top: 0px;
  `;
  
  const Group12 = styled.div`
      width: 60px;
      height: 48px;
      position: absolute;
      left: 284px;
      top: -0px;
  `;
  
  const Rectangle2_0005 = styled.div`
      width: 60px;
      height: 48px;
      background-color: white;
      border-radius: 6px;
      position: absolute;
      left: 0px;
      top: 0px;
  `;
  
  const Rectangle2_0006 = styled.div`
      width: 344px;
      height: 48px;
      border: solid 2px white;
      border-radius: 6px;
      position: absolute;
      left: 0px;
      top: 0px;
  `;
  
  const CorreoElectrónico = styled.span`
      color: white;
      text-overflow: ellipsis;
      font-size: 21px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 500;
      text-align: left;
      width: 229px;
      min-height: 20px;
      position: absolute;
      left: 31px;
      top: 10px;
      height: 20px;
  `;
  
  const IconsaxLinearSend = styled.div`
      width: 32px;
      height: 31px;
      overflow: hidden;
      position: absolute;
      left: 299px;
      top: 7px;
  `;
  
  const Vector_0003 = styled.img`
      object-fit: cover;
      position: absolute;
      left: 4px;
      top: 4px;
      right: 4px;
      bottom: 4px;
  `;
  
  const Line1 = styled.div`
      width: 1920px;
      height: 0px;
      border-top: solid 1px white;
      position: absolute;
      left: 0px;
      top: 375px;
  `;
  
  const NamasteBlanco1 = styled.img`
      width: 232px;
      height: 174px;
      object-fit: cover;
      position: absolute;
      left: 163px;
      top: 54px;
  `;
  
  const AcademíaDescúbrete = styled.span`
      color: white;
      text-overflow: ellipsis;
      font-size: 24px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: justify;
      text-transform: uppercase;
      position: absolute;
      left: 919px;
      top: 79px;
  `;
  
  const Contacto = styled.span`
      color: white;
      text-overflow: ellipsis;
      font-size: 24px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: justify;
      text-transform: uppercase;
      position: absolute;
      left: 1370px;
      top: 79px;
  `;
  
  const NochesDeNamasteMeditaton = styled.span`
      color: white;
      text-overflow: ellipsis;
      font-size: 21px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 500;
      text-align: justify;
      position: absolute;
      left: 919px;
      top: 137px;
  `;
  
  const RecibeNuestrasPromocionesONoticiasAntesQueNadie = styled.span`
      color: white;
      text-overflow: ellipsis;
      font-size: 21px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 500;
      text-align: justify;
      position: absolute;
      left: 511px;
      top: 137px;
  `;
  
  const ÚneteAlTeam = styled.span`
      color: white;
      text-overflow: ellipsis;
      font-size: 24px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: justify;
      text-transform: uppercase;
      position: absolute;
      left: 511px;
      top: 88px;
  `;
  
  const HolaYessnamasteCom = styled.span`
      color: white;
      text-overflow: ellipsis;
      font-size: 21px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 500;
      text-align: justify;
      position: absolute;
      left: 1370px;
      top: 137px;
  `;
  
  const CentroDeAyudaPreguntasFrecuentesPoliticasDePrivacidadTerminosYCondiciones = styled.span`
      color: white;
      text-overflow: ellipsis;
      font-size: 21px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 500;
      text-align: justify;
      position: absolute;
      left: 1370px;
      top: 205px;
  `;
  
  const SíguemeEnMisRedes = styled.span`
      color: white;
      text-overflow: ellipsis;
      font-size: 21px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 500;
      text-align: justify;
      position: absolute;
      left: 1368px;
      top: 409px;
  `;
  
  const YessNamaste2023 = styled.span`
      color: white;
      text-overflow: ellipsis;
      font-size: 21px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 500;
      text-align: justify;
      position: absolute;
      left: 511px;
      top: 404px;
  `;
  
  const IconsaxLinearInstagram = styled.img`
      width: 30px;
      height: 30px;
      object-fit: cover;
      position: absolute;
      left: 1243px;
      top: 407px;
  `;
  
  const IconsaxLinearFacebook = styled.img`
      width: 28px;
      height: 28px;
      object-fit: cover;
      position: absolute;
      left: 1289px;
      top: 409px;
  `;
  
  const IconsaxLinearSend_0001 = styled.img`
      width: 24px;
      height: 24px;
      object-fit: cover;
      position: absolute;
      left: 793px;
      top: 227px;
  `;
  
  const Group1 = styled.div`
      width: 1933px;
      height: 100px;
      position: absolute;
      left: -13px;
      top: 0px;
  `;
  
  const Rectangle1 = styled.div`
      width: 1933px;
      height: 100px;
      box-shadow: 0px 4px 20px  rgba(0, 0, 0, 0.1);
      background-color: white;
      position: absolute;
      left: 0px;
      top: 0px;
  `;
  
  const Home_0001 = styled.span`
      color: rgb(0, 140, 154);
      text-overflow: ellipsis;
      font-size: 21px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: left;
      position: absolute;
      left: 1089px;
      top: 43px;
  `;
  
  const Cursos = styled.span`
      color: rgb(39, 71, 88);
      text-overflow: ellipsis;
      font-size: 21px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: left;
      position: absolute;
      left: 1210px;
      top: 43px;
  `;
  
  const SobreMí = styled.span`
      color: rgb(39, 71, 88);
      text-overflow: ellipsis;
      font-size: 21px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: left;
      position: absolute;
      left: 1360px;
      top: 43px;
  `;
  
  const Contacto_0001 = styled.span`
      color: rgb(39, 71, 88);
      text-overflow: ellipsis;
      font-size: 21px;
      font-family: "Sweet Sans Pro", sans-serif;
      font-weight: 700;
      text-align: left;
      position: absolute;
      left: 1525px;
      top: 43px;
  `;
  
  const IconsaxLinearProfilecircle = styled.img`
      width: 36px;
      height: 36px;
      object-fit: cover;
      position: absolute;
      left: 1748px;
      top: 36px;
  `;
  
  const Logo = styled.div`
      width: 194px;
      height: 60px;
      overflow: hidden;
      position: absolute;
      left: 136px;
      top: 20px;
  `;
  
  const Group = styled.div`
      width: 194px;
      height: 60px;
      position: absolute;
      left: 0px;
      top: 0px;
  `;
  
  const Group_0001 = styled.div`
      width: 80px;
      height: 60px;
      position: absolute;
      left: 0px;
      top: 0px;
  `;
  
  const Group_0002 = styled.div`
      width: 71px;
      height: 60px;
      position: absolute;
      left: 0px;
      top: 0px;
  `;
  
  const Vector_0004 = styled.svg`
      width: 13px;
      height: 24px;
      position: absolute;
      left: 28px;
      top: 28px;
      right: 30px;
      bottom: 8px;
  `;
  
  const Vector_0005 = styled.svg`
      width: 26px;
      height: 27px;
      position: absolute;
      left: 44px;
      top: 26px;
      right: -0px;
      bottom: 7px;
  `;
  
  const Group_0004 = styled.div`
      width: 35px;
      height: 60px;
      position: absolute;
      left: 0px;
      top: 0px;
  `;
  
  const Vector_0006 = styled.svg`
      width: 35px;
      height: 60px;
      position: absolute;
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 0px;
  `;
  
  const Vector_0007 = styled.svg`
      width: 8px;
      height: 8px;
      position: absolute;
      left: 13px;
      top: 2px;
      right: 15px;
      bottom: 50px;
  `;
  
  const Group_0005 = styled.div`
      width: 2px;
      height: 43px;
      position: absolute;
      left: 79px;
      top: 16px;
  `;
  
  const Group_0006 = styled.div`
      width: 2px;
      height: 43px;
      position: absolute;
      left: 0px;
      top: 0px;
  `;
  
  const Vector_0008 = styled.svg`
      width: 2px;
      height: 43px;
      position: absolute;
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 0px;
  `;
  
  const Vector_0009 = styled.svg`
      width: 104px;
      height: 20px;
      position: absolute;
      left: 90px;
      top: 30px;
      right: 0px;
      bottom: 10px;
  `;
  
  const Vector_0010 = styled.svg`
      width: 32px;
      height: 33px;
      position: absolute;
      left: 1821px;
      top: 36px;
      right: 80px;
      bottom: 31px;
  `;