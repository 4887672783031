import React from "react";
import styled from "@emotion/styled";

export function About() {
	return (
		<RootWrapperSobremi>
			<TodoComenzóEnMiAdolescenciaUnaEtapaQueRebelabaLaSumaDeTraumasAdquiridosEnMiInfanciaParaLoCualMisPadresMeObligaronATomarTerapiaPsicologíaGestaltDespuésDeHaberSidoConstantePorVariosAñosYHaberMejoradoEnMuchísimosAspectosTantoLaRelaciónConmigoComoConLosDemásFueIncrementandoMiInterésPorTodoLoRelacionadoConElDesarrolloPersonal>
				Todo comenzó en mi adolescencia, una etapa que rebelaba la suma de traumas adquiridos en mi infancia para lo cual mis padres me obligaron a tomar terapia (psicología Gestalt), después de haber sido constante por varios años y haber mejorado en muchísimos aspectos tanto la relación conmigo como con los demás fue incrementando mi interés por todo lo relacionado con el desarrollo personal.
			</TodoComenzóEnMiAdolescenciaUnaEtapaQueRebelabaLaSumaDeTraumasAdquiridosEnMiInfanciaParaLoCualMisPadresMeObligaronATomarTerapiaPsicologíaGestaltDespuésDeHaberSidoConstantePorVariosAñosYHaberMejoradoEnMuchísimosAspectosTantoLaRelaciónConmigoComoConLosDemásFueIncrementandoMiInterésPorTodoLoRelacionadoConElDesarrolloPersonal>
			<VivoImpresionadaPorLaCapacidadTanGrandeQueTenemosDeModificarYDefinirNuestroEntornoPorSupuestoPartiendoDeNosotrosMismosHeSidoMiPrincipalConejilloDeIndiasTodasLasTécnicasQueTeHeCompartidoLasHePuestoEnPrácticaPorAñosCuálEsMejorOCualMeHaFuncionadoMásTodasLaRealidadEsQueTePuedesEnfocarEnUnaSolaYTeResultaráEnVariasYTeResultaráQuéEsLoQueHaceLaMagiaTuConstanciaCuandoTePonesEnAcciónYEresPerseveranteTenPorSeguroQueTuCambioComenzaráAManifestarseDesdeLoEmocionalFísicoMentalProfesionalFamiliarYTantosFactoresMás>
				Vivo impresionada por la capacidad tan grande que tenemos de modificar y definir nuestro entorno, por supuesto, partiendo de nosotros mismos. He sido mi principal conejillo de indias, todas las técnicas que te he compartido las he puesto en práctica por años; ¿Cuál es mejor o cual me ha funcionado más? TODAS, la realidad es que te puedes enfocar en una sola y te resultará, en varias y te resultará, ¿qué es lo que hace la magia? TU CONSTANCIA; cuando te pones en acción y eres perseverante ten por seguro que tu cambio comenzará a manifestarse desde lo emocional, físico, mental, profesional, familiar y tantos factores más.
			</VivoImpresionadaPorLaCapacidadTanGrandeQueTenemosDeModificarYDefinirNuestroEntornoPorSupuestoPartiendoDeNosotrosMismosHeSidoMiPrincipalConejilloDeIndiasTodasLasTécnicasQueTeHeCompartidoLasHePuestoEnPrácticaPorAñosCuálEsMejorOCualMeHaFuncionadoMásTodasLaRealidadEsQueTePuedesEnfocarEnUnaSolaYTeResultaráEnVariasYTeResultaráQuéEsLoQueHaceLaMagiaTuConstanciaCuandoTePonesEnAcciónYEresPerseveranteTenPorSeguroQueTuCambioComenzaráAManifestarseDesdeLoEmocionalFísicoMentalProfesionalFamiliarYTantosFactoresMás>
			<RecuerdoQuePosteriorACadaSesiónQueTomabaMeMostrabaCuriosaPorSaberDeDóndeProveníaLaRaízDeLaTécnicaQueMisTerapeutasUtilizabanYDeAhíSurgióMiPasiónPorEstudiarTodoLoQueMeIbaBrindandoEseProfundoDespertarLasPrimerasTécnicasQueAprendíFueronProgramaciónNeurolingüísticaReikiEftHipnosisZPointAfirmacionesPsicologíaPrácticaEspiritualidadPosteriormenteLaVidaMePusoEnElCaminoLasConstelacionesSistémicasYFamiliaresElMindfulnessYElBudismoLaCanalizaciónDeÁngelesYArcángelesZhiNengQiGongHoOponoponoKabbalahYPodríaContinuarYaQueEsUnaEnormePasiónLaQueSientoPorTodoLoQueGenereProfundoBienestarAperturaDeConscienciaYQueNosReconectaConLaConfianzaDeRegirnosPorNuestraHumanidadYNoPorUnaFalsaIdentidad>
				Recuerdo que, posterior a cada sesión que tomaba me mostraba curiosa por saber de dónde provenía la raíz de la técnica que mis terapeutas utilizaban y de ahí surgió mi pasión por estudiar todo lo que me iba brindando ese profundo despertar. Las primeras técnicas que aprendí fueron: Programación neurolingüística, reiki, EFT, hipnosis, zPoint, afirmaciones, psicología práctica, espiritualidad;  posteriormente la vida me puso en el camino las constelaciones sistémicas y familiares, el mindfulness y el budismo, la canalización de ángeles y arcángeles, , ZhiNeng QiGong, Ho'oponopono, kabbalah y podría continuar ya que es una enorme pasión la que siento por todo lo que genere profundo bienestar, apertura de consciencia y que nos reconecta con la confianza de regirnos por nuestra humanidad y no por una falsa identidad.
			</RecuerdoQuePosteriorACadaSesiónQueTomabaMeMostrabaCuriosaPorSaberDeDóndeProveníaLaRaízDeLaTécnicaQueMisTerapeutasUtilizabanYDeAhíSurgióMiPasiónPorEstudiarTodoLoQueMeIbaBrindandoEseProfundoDespertarLasPrimerasTécnicasQueAprendíFueronProgramaciónNeurolingüísticaReikiEftHipnosisZPointAfirmacionesPsicologíaPrácticaEspiritualidadPosteriormenteLaVidaMePusoEnElCaminoLasConstelacionesSistémicasYFamiliaresElMindfulnessYElBudismoLaCanalizaciónDeÁngelesYArcángelesZhiNengQiGongHoOponoponoKabbalahYPodríaContinuarYaQueEsUnaEnormePasiónLaQueSientoPorTodoLoQueGenereProfundoBienestarAperturaDeConscienciaYQueNosReconectaConLaConfianzaDeRegirnosPorNuestraHumanidadYNoPorUnaFalsaIdentidad>
			<Rectangle4 />
			<YessHernández>
				yess<br />
				hernández
			</YessHernández>
			<HanPasado18AñosDesdeQueComencéEstaAventuraDeTransformaciónPersonalLaCualSeVolvióMiPasiónYQueALoLargoDelTiempoHeConvertidoEnMiMisiónEnMiPropósitoQueEs>
				Han pasado 18 años desde que comencé esta aventura de transformación personal la cual se volvió mi pasión y que a lo
				<br />
				largo del tiempo he convertido en mi misión, en mi propósito que es:
			</HanPasado18AñosDesdeQueComencéEstaAventuraDeTransformaciónPersonalLaCualSeVolvióMiPasiónYQueALoLargoDelTiempoHeConvertidoEnMiMisiónEnMiPropósitoQueEs>
			<AyudarALasPersonasATransformarSuEnfoqueParaConstruirEntornosDeProfundoBienestarYResaltarSuNaturalezaHumanaQueSeBasaEnLaBondadLaCompasiónYElAmor>
				“Ayudar a las personas a transformar su enfoque
				para construir entornos de profundo bienestar y resaltar su naturaleza humana que se basa en la bondad, la compasión y el amor”.
			</AyudarALasPersonasATransformarSuEnfoqueParaConstruirEntornosDeProfundoBienestarYResaltarSuNaturalezaHumanaQueSeBasaEnLaBondadLaCompasiónYElAmor>
			<SíguemeYConectaConmigo>
				sígueme y conecta conmigo
			</SíguemeYConectaConmigo>
			<Group14>
				<Rectangle6 />
				<Group13>
					<Group11>
						<Group12>
							<Rectangle2 />
						</Group12>
						<Rectangle2_0001 />
						<CorreoElectrónico>
							Correo electrónico
						</CorreoElectrónico>
					</Group11>
					<IconsaxLinearSend>
						<Vector src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/75365cdd-63c6-46be-af20-e647b63b3145" alt="image of Vector" />
					</IconsaxLinearSend>
				</Group13>
				<Line1 />
				<NamasteBlanco1 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/5748c828-1e19-4ece-a0d4-2d97b3688747" alt="image of NamasteBlanco1" />
				<AcademíaDescúbrete>
					academía descúbrete
				</AcademíaDescúbrete>
				<Contacto>
					contacto
				</Contacto>
				<NochesDeNamasteMeditaton>
					Noches de namaste<br />
					Meditaton
				</NochesDeNamasteMeditaton>
				<RecibeNuestrasPromocionesONoticiasAntesQueNadie>
					Recibe nuestras promociones<br />
					o noticias antes que nadie
				</RecibeNuestrasPromocionesONoticiasAntesQueNadie>
				<ÚneteAlTeam>
					Únete al team
				</ÚneteAlTeam>
				<HolaYessnamasteCom>
					hola@yessnamaste.com
				</HolaYessnamasteCom>
				<CentroDeAyudaPreguntasFrecuentesPoliticasDePrivacidadTerminosYCondiciones>
					Centro de ayuda
					<br />
					Preguntas frecuentes
					<br />
					Politicas de privacidad
					<br />
					Terminos y condiciones
				</CentroDeAyudaPreguntasFrecuentesPoliticasDePrivacidadTerminosYCondiciones>
				<SíguemeEnMisRedes>
					Sígueme en mis redes
				</SíguemeEnMisRedes>
				<YessNamaste2023>
					© Yess Namaste 2023
				</YessNamaste2023>
				<IconsaxLinearInstagram src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/51547fae-08cc-4112-a1de-08c389ccfc2a" alt="icon" />
				<IconsaxLinearFacebook src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/79ff32b4-79f8-4104-abb3-21360f02aa55" alt="icon" />
			</Group14>
			<MaskGroup>
				<MaskGroup_0001>
					<SunInWomanSHeadMindsetAndPsychologyConcep20220915023018Utc1 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/cb60cc4f-b513-4d29-9e51-05eaa9470a54" alt="image of SunInWomanSHeadMindsetAndPsychologyConcep20220915023018Utc1" />
				</MaskGroup_0001>
			</MaskGroup>
			<Ellipse3 />
			<YAsíEsComoSeHanIdoVolando15AñosDeConstanteEstudioIndagaciónPrácticaProfundaTransformaciónYGrandiosasDiosicendiasYaQueTodasEstasTécnicasLasHeAprendidoConProfesoresExtraordinariosAquíEnMéxicoYQueAdemásDeAprenderTantoDeEllosTambiénSeConvirtieronEnGrandesAmigosMotivándomeATomarSeminariosCursosPlaticasConLosMayoresExponentesANivelMundialComoTonyRobbinsGrantConollyLuoiseHayYehudaBergRamDahsWayneDyerBertHellingerMiMásAmadoMaestroEspiritualTichNatchHannYTantasPersonasSumamenteMaravillosasQueAunqueAlgunosYaHanTrascendidoParaMiHanSidoYSeguiránSiendoInspiraciónParaDejarUnMundoMejor>
				Y así es como se han ido volando 15 años de constante estudio, indagación, práctica, profunda transformación y grandiosas Diosicendias, ya que todas estas técnicas las he aprendido con profesores extraordinarios aquí en México y que además de aprender tanto de ellos, también se convirtieron en grandes amigos motivándome a tomar seminarios, cursos, platicas con los mayores exponentes a nivel mundial como Tony Robbins, Grant Conolly, Luoise Hay, Yehuda Berg, Ram Dahs, Wayne Dyer, Bert Hellinger, mi más amado maestro espiritual Tich Natch Hann y tantas personas sumamente maravillosas que aunque algunos ya han trascendido, para mi han sido y seguirán siendo inspiración para dejar un mundo mejor.
			</YAsíEsComoSeHanIdoVolando15AñosDeConstanteEstudioIndagaciónPrácticaProfundaTransformaciónYGrandiosasDiosicendiasYaQueTodasEstasTécnicasLasHeAprendidoConProfesoresExtraordinariosAquíEnMéxicoYQueAdemásDeAprenderTantoDeEllosTambiénSeConvirtieronEnGrandesAmigosMotivándomeATomarSeminariosCursosPlaticasConLosMayoresExponentesANivelMundialComoTonyRobbinsGrantConollyLuoiseHayYehudaBergRamDahsWayneDyerBertHellingerMiMásAmadoMaestroEspiritualTichNatchHannYTantasPersonasSumamenteMaravillosasQueAunqueAlgunosYaHanTrascendidoParaMiHanSidoYSeguiránSiendoInspiraciónParaDejarUnMundoMejor>
			<CuandoComencéADarSesionesPersonalizadasUnoDeMisConsultantesMeRecomendóConOtraPersonaQuienASuVezMePreguntóConQuéTécnicaTrabajasYTeConfiesoQueDudeEnCómoResponderleYaQueNoTengoUnaSolaAsíQueLeExpliqueQueEnCadaSesiónBuscoAcompañarlesYGuiarlesATravésDeUnProcesoDigeridoYSumamentePrácticoEnfocadoHaciaLaConexiónContigoMismoYEsasPartesDeTiQueDesconocesOQueNoTienesTanConscientesConElObjetivoDeImpactarDeManeraPositivaTuMenteYComportamientosHábitosOtorgándoteAdemásEficacesHerramientasQuePodrásAplicarParaMejorarSustancialmenteTuCalidadDeVidaYSerLaMejorVersiónDeTiDeTalFormaQueNoTeDigoLoQueCreoQueDeberíasDeHacerSentirOPensarLoQueHagoEsDespertarLaBondadDeTuCorazónParaAbrirteLosCaminosLasInfinitasPosibilidadesParaQueTúSeasElQueAlReflexionarConecteConLaRespuestaQueTantoDeseabasEscucharPermitiéndoteDisfrutarteYAmarte>
				Cuando comencé a dar sesiones personalizadas uno de mis consultantes me recomendó con otra persona quien a su vez me preguntó: ¿con qué técnica trabajas? y te confieso que dude en cómo responderle ya que no tengo una sola, así que le explique que en cada sesión busco acompañarles y guiarles a través de un proceso digerido y sumamente práctico, enfocado hacia la conexión contigo mismo y esas partes de ti que desconoces o que no tienes tan conscientes, con el objetivo de impactar de manera positiva tu mente y comportamientos/hábitos, otorgándote además eficaces herramientas que podrás aplicar para mejorar sustancialmente tu calidad de vida y ser la mejor versión de ti; de tal forma que no te digo lo que creo que deberías de hacer, sentir, o pensar; lo que hago es despertar la bondad de tu corazón para abrirte los caminos, las infinitas posibilidades para que tú seas el que al reflexionar conecte con la respuesta que tanto deseabas escuchar, permitiéndote disfrutarte y amarte.<br />

			</CuandoComencéADarSesionesPersonalizadasUnoDeMisConsultantesMeRecomendóConOtraPersonaQuienASuVezMePreguntóConQuéTécnicaTrabajasYTeConfiesoQueDudeEnCómoResponderleYaQueNoTengoUnaSolaAsíQueLeExpliqueQueEnCadaSesiónBuscoAcompañarlesYGuiarlesATravésDeUnProcesoDigeridoYSumamentePrácticoEnfocadoHaciaLaConexiónContigoMismoYEsasPartesDeTiQueDesconocesOQueNoTienesTanConscientesConElObjetivoDeImpactarDeManeraPositivaTuMenteYComportamientosHábitosOtorgándoteAdemásEficacesHerramientasQuePodrásAplicarParaMejorarSustancialmenteTuCalidadDeVidaYSerLaMejorVersiónDeTiDeTalFormaQueNoTeDigoLoQueCreoQueDeberíasDeHacerSentirOPensarLoQueHagoEsDespertarLaBondadDeTuCorazónParaAbrirteLosCaminosLasInfinitasPosibilidadesParaQueTúSeasElQueAlReflexionarConecteConLaRespuestaQueTantoDeseabasEscucharPermitiéndoteDisfrutarteYAmarte>
			<YLaMismaEsenciaEsLaQueEncontrarásEnTodosMisCursosAudiosYVideosUnEstiloMuyCaracterísticoRelajadoYDivertidoSiempreMotivadaPorElDeseoDeExpandirLuzSonrisasYPaz>
				Y la misma esencia es la que encontrarás en todos mis cursos, audios y videos; un estilo muy característico, relajado y divertido; siempre motivada por el deseo de expandir luz, sonrisas y paz.<br />

			</YLaMismaEsenciaEsLaQueEncontrarásEnTodosMisCursosAudiosYVideosUnEstiloMuyCaracterísticoRelajadoYDivertidoSiempreMotivadaPorElDeseoDeExpandirLuzSonrisasYPaz>
			<GraciasPorPermitirmeAbrazarTuCorazónNamaste>
				Gracias por permitirme abrazar tu Corazón.<br />
				Namaste
			</GraciasPorPermitirmeAbrazarTuCorazónNamaste>
			<UnaLuzAlimentaAOtraTuMeAlimentasAMíYoTeAlimentoATiYJuntosSomosUnInmensoDomoDeLuz>
				“Una luz alimenta a otra, tu me alimentas a mí, yo te alimento a ti y juntos somos un inmenso domo de luz”
			</UnaLuzAlimentaAOtraTuMeAlimentasAMíYoTeAlimentoATiYJuntosSomosUnInmensoDomoDeLuz>
			<MaskGroup_0002>
				<MaskGroup_0003>
					<Pp1 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/93665ef7-14c3-4f6a-ac00-78726bf3c3c6" alt="image of Pp1" />
				</MaskGroup_0003>
			</MaskGroup_0002>
			<MaskGroup_0004>
				<MaskGroup_0003>
					<Pp1_0001 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/bb2fe168-db91-451e-a520-b99f8031acc2" alt="image of Pp1" />
				</MaskGroup_0003>
			</MaskGroup_0004>
			<MaskGroup_0006>
				<MaskGroup_0001>
					<PsychologyConcept20210902042758Utc1 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/58e17294-1655-464c-baf9-d8e8972da1b4" alt="image of PsychologyConcept20210902042758Utc1" />
				</MaskGroup_0001>
			</MaskGroup_0006>
			<MaskGroup_0008>
				<MaskGroup_0009>
					<WellnessMentalHealthGiftForMeditationAtHo20211111054639Utc2 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/c7de3597-72df-47e2-bdea-a0070c089d90" alt="image of WellnessMentalHealthGiftForMeditationAtHo20211111054639Utc2" />
				</MaskGroup_0009>
			</MaskGroup_0008>
			<IconsaxOutlineInstagram>
				<Vector_0008 xmlns="http://www.w3.org/2000/svg">
					<path fill="rgb(254, 158, 15)" d="M25.7812 40.3125L14.5312 40.3125C4.35 40.3125 0 35.9625 0 25.7812L0 14.5312C0 4.35 4.35 0 14.5312 0L25.7812 0C35.9625 0 40.3125 4.35 40.3125 14.5312L40.3125 25.7812C40.3125 35.9625 35.9625 40.3125 25.7812 40.3125ZM14.5312 2.8125C5.8875 2.8125 2.8125 5.8875 2.8125 14.5312L2.8125 25.7812C2.8125 34.425 5.8875 37.5 14.5312 37.5L25.7812 37.5C34.425 37.5 37.5 34.425 37.5 25.7812L37.5 14.5312C37.5 5.8875 34.425 2.8125 25.7812 2.8125L14.5312 2.8125Z" />
				</Vector_0008>
				<Vector_0009 xmlns="http://www.w3.org/2000/svg">
					<path fill="rgb(254, 158, 15)" d="M7.96875 20.1508C5.85683 20.1459 3.83282 19.3047 2.33946 17.8114C0.846104 16.318 0.00495177 14.294 0 12.1821C0.00495177 10.0702 0.846104 8.04616 2.33946 6.5528C3.83282 5.05944 5.85683 4.21829 7.96875 4.21334C10.0807 4.21829 12.1047 5.05944 13.598 6.5528C15.0914 8.04616 15.9325 10.0702 15.9375 12.1821C15.9325 14.294 15.0914 16.318 13.598 17.8114C12.1047 19.3047 10.0807 20.1459 7.96875 20.1508ZM7.96875 7.02584C5.11875 7.02584 2.8125 9.33209 2.8125 12.1821C2.8125 15.0321 5.11875 17.3383 7.96875 17.3383C10.8188 17.3383 13.125 15.0321 13.125 12.1821C13.125 9.33209 10.8188 7.02584 7.96875 7.02584ZM17.3438 3.74459C17.1 3.74459 16.8563 3.68834 16.6313 3.59459C16.4063 3.50084 16.2 3.36959 16.0125 3.20084C15.8438 3.01334 15.6937 2.80709 15.6 2.58209C15.5097 2.35557 15.4651 2.11342 15.4688 1.86959C15.4688 1.62584 15.5062 1.38209 15.6 1.15709C15.7125 0.913337 15.8438 0.725837 16.0125 0.538337C16.1063 0.463337 16.2 0.369587 16.2938 0.313337C16.4063 0.238337 16.5188 0.182087 16.6313 0.144587C16.973 0.00248532 17.3489 -0.0359513 17.7123 0.0340626C18.0758 0.104077 18.4105 0.279447 18.675 0.538337C18.8437 0.725837 18.975 0.913337 19.0688 1.15709C19.1625 1.38209 19.2188 1.62584 19.2188 1.86959C19.2188 2.11334 19.1625 2.35709 19.0688 2.58209C18.975 2.80709 18.8437 3.01334 18.675 3.20084C18.4875 3.36959 18.2812 3.50084 18.0562 3.59459C17.8312 3.68834 17.5875 3.74459 17.3438 3.74459Z" />
				</Vector_0009>
			</IconsaxOutlineInstagram>
			<IconsaxBoldFacebook>
				<Vector_0010 xmlns="http://www.w3.org/2000/svg">
					<path fill="rgb(254, 158, 15)" d="M40.8333 28.9712C40.8333 36.4029 36.4029 40.8333 28.9712 40.8333L26.5417 40.8333C25.4187 40.8333 24.5 39.9146 24.5 38.7917L24.5 27.0112C24.5 26.46 24.9492 25.9904 25.5004 25.9904L29.0937 25.9292C29.3796 25.9087 29.6246 25.7046 29.6858 25.4187L30.4004 21.5192C30.4143 21.4308 30.4088 21.3404 30.3844 21.2544C30.36 21.1683 30.3172 21.0885 30.259 21.0206C30.2007 20.9527 30.1285 20.8982 30.0472 20.8609C29.9658 20.8236 29.8774 20.8044 29.7879 20.8046L25.4392 20.8658C24.8675 20.8658 24.4183 20.4167 24.3979 19.8654L24.3162 14.8633C24.3162 14.5367 24.5817 14.2508 24.9287 14.2508L29.8287 14.1692C30.1758 14.1692 30.4412 13.9037 30.4412 13.5567L30.3596 8.65667C30.3596 8.30958 30.0942 8.04417 29.7471 8.04417L24.2346 8.12583C23.4299 8.13806 22.6355 8.30896 21.897 8.62874C21.1584 8.94853 20.4903 9.4109 19.9308 9.98938C19.3712 10.5679 18.9314 11.2511 18.6364 11.9998C18.3414 12.7486 18.1971 13.5483 18.2117 14.3529L18.3137 19.9675C18.3342 20.5392 17.885 20.9883 17.3133 21.0087L14.8633 21.0496C14.5162 21.0496 14.2508 21.315 14.2508 21.6621L14.3121 25.5412C14.3121 25.8883 14.5775 26.1537 14.9246 26.1537L17.3746 26.1129C17.9462 26.1129 18.3954 26.5621 18.4158 27.1133L18.5996 38.7508C18.62 39.8942 17.7012 40.8333 16.5579 40.8333L11.8621 40.8333C4.43042 40.8333 0 36.4029 0 28.9508L0 11.8621C0 4.43042 4.43042 0 11.8621 0L28.9712 0C36.4029 0 40.8333 4.43042 40.8333 11.8621L40.8333 28.9712Z" />
				</Vector_0010>
			</IconsaxBoldFacebook>
			<IconsaxBoldYoutube>
				<Vector_0011 xmlns="http://www.w3.org/2000/svg">
					<path fill="rgb(254, 158, 15)" d="M36.25 0L12.0833 0C4.83333 0 0 4.83333 0 12.0833L0 26.5833C0 33.8333 4.83333 38.6667 12.0833 38.6667L36.25 38.6667C43.5 38.6667 48.3333 33.8333 48.3333 26.5833L48.3333 12.0833C48.3333 4.83333 43.5 0 36.25 0ZM28.7342 21.8225L22.765 25.3992C20.3483 26.8492 18.3667 25.7375 18.3667 22.91L18.3667 15.7325C18.3667 12.905 20.3483 11.7933 22.765 13.2433L28.7342 16.82C31.03 18.2217 31.03 20.445 28.7342 21.8225Z" />
				</Vector_0011>
			</IconsaxBoldYoutube>
			<IconsaxBoldFacebook_0001 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/2d101828-7889-4adf-9382-d7b21fffa86c" alt="icon" />
			<IconsaxBoldInstagram src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/1aaef919-9678-4e66-9904-5aac3d477cbe" alt="icon" />
			<IconsaxOutlineInstagram_0001 src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/1b0a9519-7d71-4e0a-b626-c3e3fb799369" alt="icon" />
		</RootWrapperSobremi>
	)
}

const RootWrapperSobremi = styled.div`
	min-height: 100vh;
	background-color: white;
	position: relative;
`;

const Rectangle5 = styled.div`
	width: 1933px;
	height: 978px;
	background-color: rgba(254, 158, 15, 0.1);
	position: absolute;
	left: -6px;
	top: 2123px;
`;

const TodoComenzóEnMiAdolescenciaUnaEtapaQueRebelabaLaSumaDeTraumasAdquiridosEnMiInfanciaParaLoCualMisPadresMeObligaronATomarTerapiaPsicologíaGestaltDespuésDeHaberSidoConstantePorVariosAñosYHaberMejoradoEnMuchísimosAspectosTantoLaRelaciónConmigoComoConLosDemásFueIncrementandoMiInterésPorTodoLoRelacionadoConElDesarrolloPersonal = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 26px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: left;
	width: 674px;
	position: absolute;
	left: 328px;
	top: 1065px;
`;

const VivoImpresionadaPorLaCapacidadTanGrandeQueTenemosDeModificarYDefinirNuestroEntornoPorSupuestoPartiendoDeNosotrosMismosHeSidoMiPrincipalConejilloDeIndiasTodasLasTécnicasQueTeHeCompartidoLasHePuestoEnPrácticaPorAñosCuálEsMejorOCualMeHaFuncionadoMásTodasLaRealidadEsQueTePuedesEnfocarEnUnaSolaYTeResultaráEnVariasYTeResultaráQuéEsLoQueHaceLaMagiaTuConstanciaCuandoTePonesEnAcciónYEresPerseveranteTenPorSeguroQueTuCambioComenzaráAManifestarseDesdeLoEmocionalFísicoMentalProfesionalFamiliarYTantosFactoresMás = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 26px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: left;
	width: 674px;
	position: absolute;
	left: 877px;
	top: 2548px;
`;

const RecuerdoQuePosteriorACadaSesiónQueTomabaMeMostrabaCuriosaPorSaberDeDóndeProveníaLaRaízDeLaTécnicaQueMisTerapeutasUtilizabanYDeAhíSurgióMiPasiónPorEstudiarTodoLoQueMeIbaBrindandoEseProfundoDespertarLasPrimerasTécnicasQueAprendíFueronProgramaciónNeurolingüísticaReikiEftHipnosisZPointAfirmacionesPsicologíaPrácticaEspiritualidadPosteriormenteLaVidaMePusoEnElCaminoLasConstelacionesSistémicasYFamiliaresElMindfulnessYElBudismoLaCanalizaciónDeÁngelesYArcángelesZhiNengQiGongHoOponoponoKabbalahYPodríaContinuarYaQueEsUnaEnormePasiónLaQueSientoPorTodoLoQueGenereProfundoBienestarAperturaDeConscienciaYQueNosReconectaConLaConfianzaDeRegirnosPorNuestraHumanidadYNoPorUnaFalsaIdentidad = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 26px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: left;
	width: 674px;
	min-height: 532px;
	position: absolute;
	left: 874px;
	top: 1479px;
	height: 532px;
`;

const Rectangle4 = styled.div`
	width: 1933px;
	height: 405px;
	background-color: rgb(254, 158, 15);
	position: absolute;
	left: -6px;
	top: 100px;
`;

const YessHernández = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 72px;
	font-family: "Nib Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	text-transform: uppercase;
	position: absolute;
	left: 954px;
	top: 294px;
`;

const HanPasado18AñosDesdeQueComencéEstaAventuraDeTransformaciónPersonalLaCualSeVolvióMiPasiónYQueALoLargoDelTiempoHeConvertidoEnMiMisiónEnMiPropósitoQueEs = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 24px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: left;
	width: 547px;
	position: absolute;
	left: 954px;
	top: 544px;
`;

const AyudarALasPersonasATransformarSuEnfoqueParaConstruirEntornosDeProfundoBienestarYResaltarSuNaturalezaHumanaQueSeBasaEnLaBondadLaCompasiónYElAmor = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 24px;
	font-family: "Nib Pro", sans-serif;
	font-weight: 700;
	line-height: 156%;
	text-align: center;
	width: 981px;
	position: absolute;
	left: 397px;
	top: 794px;
`;

const SíguemeYConectaConmigo = styled.span`
	color: rgb(0, 140, 154);
	text-overflow: ellipsis;
	font-size: 42px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
	position: absolute;
	left: 608px;
	top: 4178px;
`;

const Group14 = styled.div`
	width: 1920px;
	height: 466px;
	position: absolute;
	left: 0px;
	top: 4442px;
`;

const Rectangle6 = styled.div`
	width: 1920px;
	height: 466px;
	background-color: rgb(0, 140, 154);
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Group13 = styled.div`
	width: 344px;
	height: 48px;
	position: absolute;
	left: 511px;
	top: 222px;
`;

const Group11 = styled.div`
	width: 344px;
	height: 48px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Group12 = styled.div`
	width: 60px;
	height: 48px;
	position: absolute;
	left: 284px;
	top: -0px;
`;

const Rectangle2 = styled.div`
	width: 60px;
	height: 48px;
	background-color: white;
	border-radius: 6px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Rectangle2_0001 = styled.div`
	width: 344px;
	height: 48px;
	border: solid 2px white;
	border-radius: 6px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const CorreoElectrónico = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: left;
	width: 225px;
	min-height: 20px;
	position: absolute;
	left: 31px;
	top: 10px;
	height: 20px;
`;

const IconsaxLinearSend = styled.div`
	width: 32px;
	height: 31px;
	overflow: hidden;
	position: absolute;
	left: 299px;
	top: 7px;
`;

const Vector = styled.img`
	object-fit: cover;
	position: absolute;
	left: 4px;
	top: 4px;
	right: 4px;
	bottom: 4px;
`;

const Line1 = styled.div`
	width: 1920px;
	height: 0px;
	border-top: solid 1px white;
	position: absolute;
	left: 0px;
	top: 375px;
`;

const NamasteBlanco1 = styled.img`
	width: 232px;
	height: 174px;
	object-fit: cover;
	position: absolute;
	left: 163px;
	top: 54px;
`;

const AcademíaDescúbrete = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 24px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: justify;
	text-transform: uppercase;
	position: absolute;
	left: 919px;
	top: 79px;
`;

const Contacto = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 24px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: justify;
	text-transform: uppercase;
	position: absolute;
	left: 1370px;
	top: 79px;
`;

const NochesDeNamasteMeditaton = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	position: absolute;
	left: 919px;
	top: 137px;
`;

const RecibeNuestrasPromocionesONoticiasAntesQueNadie = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	position: absolute;
	left: 511px;
	top: 137px;
`;

const ÚneteAlTeam = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 24px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: justify;
	text-transform: uppercase;
	position: absolute;
	left: 511px;
	top: 88px;
`;

const HolaYessnamasteCom = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	position: absolute;
	left: 1370px;
	top: 137px;
`;

const CentroDeAyudaPreguntasFrecuentesPoliticasDePrivacidadTerminosYCondiciones = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	position: absolute;
	left: 1370px;
	top: 205px;
`;

const SíguemeEnMisRedes = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	position: absolute;
	left: 1368px;
	top: 409px;
`;

const YessNamaste2023 = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: justify;
	position: absolute;
	left: 511px;
	top: 404px;
`;

const IconsaxLinearInstagram = styled.img`
	width: 30px;
	height: 30px;
	object-fit: cover;
	position: absolute;
	left: 1243px;
	top: 407px;
`;

const IconsaxLinearFacebook = styled.img`
	width: 28px;
	height: 28px;
	object-fit: cover;
	position: absolute;
	left: 1289px;
	top: 409px;
`;

const MaskGroup = styled.div`
	width: 428px;
	height: 410px;
	position: absolute;
	left: 1110px;
	top: 984px;
`;

const MaskGroup_0001 = styled.div`
	width: 428px;
	height: 410px;
	overflow: hidden;
	position: absolute;
	left: 0px;
	top: 0px;
	border-radius: 15px;
`;

const SunInWomanSHeadMindsetAndPsychologyConcep20220915023018Utc1 = styled.img`
	width: 699px;
	height: 466px;
	object-fit: cover;
	position: absolute;
	left: -222px;
	top: -28px;
`;

const Ellipse3 = styled.div`
	width: 439px;
	height: 439px;
	background-color: rgb(255, 221, 174);
	border-radius: 439px / 439px;
	position: absolute;
	left: 385px;
	top: 3607px;
`;

const YAsíEsComoSeHanIdoVolando15AñosDeConstanteEstudioIndagaciónPrácticaProfundaTransformaciónYGrandiosasDiosicendiasYaQueTodasEstasTécnicasLasHeAprendidoConProfesoresExtraordinariosAquíEnMéxicoYQueAdemásDeAprenderTantoDeEllosTambiénSeConvirtieronEnGrandesAmigosMotivándomeATomarSeminariosCursosPlaticasConLosMayoresExponentesANivelMundialComoTonyRobbinsGrantConollyLuoiseHayYehudaBergRamDahsWayneDyerBertHellingerMiMásAmadoMaestroEspiritualTichNatchHannYTantasPersonasSumamenteMaravillosasQueAunqueAlgunosYaHanTrascendidoParaMiHanSidoYSeguiránSiendoInspiraciónParaDejarUnMundoMejor = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 26px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: center;
	width: 1217px;
	min-height: 277px;
	position: absolute;
	left: 328px;
	top: 2214px;
	height: 277px;
`;

const CuandoComencéADarSesionesPersonalizadasUnoDeMisConsultantesMeRecomendóConOtraPersonaQuienASuVezMePreguntóConQuéTécnicaTrabajasYTeConfiesoQueDudeEnCómoResponderleYaQueNoTengoUnaSolaAsíQueLeExpliqueQueEnCadaSesiónBuscoAcompañarlesYGuiarlesATravésDeUnProcesoDigeridoYSumamentePrácticoEnfocadoHaciaLaConexiónContigoMismoYEsasPartesDeTiQueDesconocesOQueNoTienesTanConscientesConElObjetivoDeImpactarDeManeraPositivaTuMenteYComportamientosHábitosOtorgándoteAdemásEficacesHerramientasQuePodrásAplicarParaMejorarSustancialmenteTuCalidadDeVidaYSerLaMejorVersiónDeTiDeTalFormaQueNoTeDigoLoQueCreoQueDeberíasDeHacerSentirOPensarLoQueHagoEsDespertarLaBondadDeTuCorazónParaAbrirteLosCaminosLasInfinitasPosibilidadesParaQueTúSeasElQueAlReflexionarConecteConLaRespuestaQueTantoDeseabasEscucharPermitiéndoteDisfrutarteYAmarte = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 26px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: center;
	width: 1217px;
	min-height: 347px;
	position: absolute;
	left: 328px;
	top: 3178px;
	height: 347px;
`;

const YLaMismaEsenciaEsLaQueEncontrarásEnTodosMisCursosAudiosYVideosUnEstiloMuyCaracterísticoRelajadoYDivertidoSiempreMotivadaPorElDeseoDeExpandirLuzSonrisasYPaz = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 26px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: left;
	width: 685px;
	min-height: 118px;
	position: absolute;
	left: 893px;
	top: 3633px;
	height: 118px;
`;

const GraciasPorPermitirmeAbrazarTuCorazónNamaste = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 26px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 500;
	text-align: left;
	position: absolute;
	left: 893px;
	top: 3955px;
`;

const UnaLuzAlimentaAOtraTuMeAlimentasAMíYoTeAlimentoATiYJuntosSomosUnInmensoDomoDeLuz = styled.span`
	color: black;
	text-overflow: ellipsis;
	font-size: 24px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	text-transform: uppercase;
	width: 685px;
	min-height: 107px;
	position: absolute;
	left: 893px;
	top: 3805px;
	height: 107px;
`;

const MaskGroup_0002 = styled.div`
	width: 439px;
	height: 439px;
	position: absolute;
	left: 385px;
	top: 236px;
`;

const MaskGroup_0003 = styled.div`
	width: 439px;
	height: 439px;
	overflow: hidden;
	position: absolute;
	left: 0px;
	top: 0px;
	border-radius: 220px;
`;

const Pp1 = styled.img`
	width: 631px;
	height: 668px;
	object-fit: cover;
	position: absolute;
	left: -62px;
	top: 0px;
`;

const MaskGroup_0004 = styled.div`
	width: 439px;
	height: 439px;
	position: absolute;
	left: 385px;
	top: 3607px;
`;

const Pp1_0001 = styled.img`
	width: 464px;
	height: 491px;
	object-fit: cover;
	position: absolute;
	left: -14px;
	top: -34px;
`;

const MaskGroup_0006 = styled.div`
	width: 428px;
	height: 410px;
	position: absolute;
	left: 362px;
	top: 2545px;
`;

const PsychologyConcept20210902042758Utc1 = styled.img`
	width: 916px;
	height: 1373px;
	object-fit: cover;
	position: absolute;
	left: -434px;
	top: -482px;
`;

const MaskGroup_0008 = styled.div`
	width: 496px;
	height: 590px;
	position: absolute;
	left: 328px;
	top: 1455px;
`;

const MaskGroup_0009 = styled.div`
	width: 496px;
	height: 590px;
	overflow: hidden;
	position: absolute;
	left: 0px;
	top: 0px;
	border-radius: 15px;
`;

const WellnessMentalHealthGiftForMeditationAtHo20211111054639Utc2 = styled.img`
	width: 964px;
	height: 643px;
	object-fit: cover;
	position: absolute;
	left: -283px;
	top: -17px;
`;

const Group1 = styled.div`
	width: 1933px;
	height: 110px;
	position: absolute;
	left: -13px;
	top: 0px;
`;

const Rectangle1 = styled.div`
	width: 1933px;
	height: 100px;
	box-shadow: 0px 4px 20px  rgba(0, 0, 0, 0.1);
	background-color: white;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Home = styled.span`
	color: rgb(39, 71, 88);
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	position: absolute;
	left: 1089px;
	top: 43px;
`;

const Cursos = styled.span`
	color: rgb(39, 71, 88);
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	position: absolute;
	left: 1210px;
	top: 43px;
`;

const SobreMí = styled.span`
	color: rgb(0, 140, 154);
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	position: absolute;
	left: 1360px;
	top: 43px;
`;

const Contacto_0001 = styled.span`
	color: rgb(39, 71, 88);
	text-overflow: ellipsis;
	font-size: 21px;
	font-family: "Sweet Sans Pro", sans-serif;
	font-weight: 700;
	text-align: left;
	position: absolute;
	left: 1525px;
	top: 43px;
`;

const IconsaxLinearProfilecircle = styled.img`
	width: 36px;
	height: 36px;
	object-fit: cover;
	position: absolute;
	left: 1748px;
	top: 36px;
`;

const Logo = styled.div`
	width: 194px;
	height: 60px;
	overflow: hidden;
	position: absolute;
	left: 136px;
	top: 20px;
`;

const Group = styled.div`
	width: 194px;
	height: 60px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Group_0001 = styled.div`
	width: 80px;
	height: 60px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Group_0002 = styled.div`
	width: 71px;
	height: 60px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Vector_0001 = styled.svg`
	width: 13px;
	height: 24px;
	position: absolute;
	left: 28px;
	top: 28px;
	right: 30px;
	bottom: 8px;
`;

const Vector_0002 = styled.svg`
	width: 26px;
	height: 27px;
	position: absolute;
	left: 44px;
	top: 26px;
	right: -0px;
	bottom: 7px;
`;

const Group_0004 = styled.div`
	width: 35px;
	height: 60px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Vector_0003 = styled.svg`
	width: 35px;
	height: 60px;
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
`;

const Vector_0004 = styled.svg`
	width: 8px;
	height: 8px;
	position: absolute;
	left: 13px;
	top: 2px;
	right: 15px;
	bottom: 50px;
`;

const Group_0005 = styled.div`
	width: 2px;
	height: 43px;
	position: absolute;
	left: 79px;
	top: 16px;
`;

const Group_0006 = styled.div`
	width: 2px;
	height: 43px;
	position: absolute;
	left: 0px;
	top: 0px;
`;

const Vector_0005 = styled.svg`
	width: 2px;
	height: 43px;
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
`;

const Vector_0006 = styled.svg`
	width: 104px;
	height: 20px;
	position: absolute;
	left: 90px;
	top: 30px;
	right: 0px;
	bottom: 10px;
`;

const Vector_0007 = styled.svg`
	width: 32px;
	height: 53px;
	position: absolute;
	left: 1821px;
	top: 57px;
	right: 80px;
	bottom: 0px;
`;

const IconsaxOutlineInstagram = styled.div`
	width: 45px;
	height: 45px;
	overflow: hidden;
	position: absolute;
	left: 937px;
	top: 4277px;
`;

const Vector_0008 = styled.svg`
	width: 40px;
	height: 40px;
	position: absolute;
	left: 2px;
	top: 2px;
	right: 2px;
	bottom: 2px;
`;

const Vector_0009 = styled.svg`
	width: 19px;
	height: 20px;
	position: absolute;
	left: 15px;
	top: 10px;
	right: 11px;
	bottom: 15px;
`;

const IconsaxBoldFacebook = styled.div`
	width: 49px;
	height: 49px;
	overflow: hidden;
	position: absolute;
	left: 847px;
	top: 4275px;
`;

const Vector_0010 = styled.svg`
	width: 41px;
	height: 41px;
	position: absolute;
	left: 4px;
	top: 4px;
	right: 4px;
	bottom: 4px;
`;

const IconsaxBoldYoutube = styled.div`
	width: 58px;
	height: 58px;
	overflow: hidden;
	position: absolute;
	left: 1016px;
	top: 4270px;
`;

const Vector_0011 = styled.svg`
	width: 48px;
	height: 39px;
	position: absolute;
	left: 5px;
	top: 10px;
	right: 5px;
	bottom: 10px;
`;

const IconsaxBoldFacebook_0001 = styled.img`
	width: 24px;
	height: 24px;
	object-fit: cover;
	position: absolute;
	left: 820px;
	top: 4296px;
`;

const IconsaxBoldInstagram = styled.img`
	width: 24px;
	height: 24px;
	object-fit: cover;
	position: absolute;
	left: 877px;
	top: 4296px;
`;

const IconsaxOutlineInstagram_0001 = styled.img`
	width: 24px;
	height: 24px;
	object-fit: cover;
	position: absolute;
	left: 912px;
	top: 4298px;
`;

